.c-ActivePharmacistPromptModal {
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF;
  width: 60%;
  max-width: 750px;
  padding: 50px;
  min-height: 300px;
  justify-content: center;
  align-items: center;
  outline: none;
  z-index: 999999999;

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .badge-instructions {
      text-align: center;
      font-weight: 600;
    }

    .loading {
      font-size: 24px;
    }

    .error {
      color: #CF6059;
    }

    .pharmacist-welcome {
      color: #3AB96F;
    }

    button {
      &.cancel-prompt {
        flex: 0 0 auto;
        color: #FFFFFF;
        background-color: #CF6059;
        border: 2px solid #CF6059;
        border-radius: 3px;
        min-width: 140px;
        font-size: 18px;
        outline: none;
        padding: 7.5px;
        margin-top: 48px;
      }
    }
  }
}
