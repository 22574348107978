@import "../../../../../../../../../../style-variables-colors.scss";


.c-StockingBatchListItemContent {
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;

  >.content-info-container {
    display: flex;
    justify-content: flex-end;

    >.stocking-user {
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;

      >.name {
        font-weight: bolder;
        word-break: break-all;
      }
    }

    >.content-info {
      display: flex;
      font-size: 14px;
      height: 100%;
      align-self: center;
      border: 1.5px solid $main-blue;
      border-radius: 4px;
      padding: 2px 8px;
      margin-top: 20px;

      >.icon {
        flex: 0 0 auto;
        margin-right: 6px;
      }

      >.info {
        flex: 1 1 auto;
      }
    }

    >.content-info-inactive {
      border-color: #E2E7E9;
    }
  }
}
