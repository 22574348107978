@import "../../../../../../../../style-variables-colors.scss";

.r-DispenseAttemptsView {
  display: flex;
  flex-direction: row;
  flex: 1 1 0px;
  overflow: hidden;

  .tabs-container {
    margin-top: 0px !important;
  }

  .dispense-attempt-wrapper {
    display: flex;
    flex-direction: column;
    min-width: 340px;
    height: 100%;
    margin: 10px 0px 0px 10px;

    .search-wrapper {
      padding-right: 10px;

      .title {
        font-size: 20px;
        color: $main-blue;
      }

      .search {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin: 15px 0;
        padding: 2px;
        border: solid 1px $light-grey;
        border-radius: 5px;

        .image {
          height: 12px;
          width: 12px;
        }

        .search-input {
          height: 16px;
          border: none;
          text-decoration: none;
          outline: none;
          width: 100%;
          margin-left: 5px;
        }
      }
    }


    .dispense-attempt-elements-wrapper {
      height: 100%;
      margin-bottom: 10px;
      display: flex;
      flex: 1 1 auto;
      flex-direction: column;

      .dispense-attempt-date-container {
        display: flex;
        flex-direction: row;
        margin: 8px 0px;
        flex: 0 0 auto;

        .separator {
          margin: auto 0;
          width: 100%;
          border: 1px solid $light-grey;
          border-left: none;
        }

        .date {
          color: $darker-grey;
          margin: 0px 15px;
          white-space: nowrap;
        }
      }
    }

  }
  .data-row,
  .data-row-no-padding,
  .meta {
    display: flex;
    flex-direction: row;
  }

  .flex-row {
    display: flex;
    flex-direction: row;
    margin: 1px 0px;
  }

  .flex-column {
    display: flex;
    flex-direction: column;
  }

  .max-height {
    height: 100%;
  }

  .max-width {
    width: 100%;
  }

  .w50 {
    width: 50%;
  }

  .w100 {
    width: 100%;
  }

  .horizontal-line {
    height: 1px;
    width: 100%;
    border: solid 0.5px $light-grey;
  }

  .vertical-line {
    height: 100%;
    border: solid 0.5px $light-grey;
  }

  .key-value-container {
    margin-bottom: 10px;

    .key {
      font-weight: lighter;
    }

    .value {
      font-weight: 600;
      word-wrap: break-word;
    }
  }

  .w33 {
    width: 33.3333%;
  }

  .padding-zero {
    padding: 0;
  }

  .text-left {
    text-align: left !important;
  }

  .drug-label,
  .physician-label,
  .rx-label {
    margin-right: 10px;
    font-weight: lighter;
  }

  .drug-name,
  .physician-name,
  .rx-value {
    color: $grey;
  }

  .kiosk-name {
    margin-top: 8px;
    text-align: right;
    color: $main-blue;

  }

  .patient-name {
    font-size: 18px;
    color: $grey;
    margin-bottom: 8px;
  }

}

