@import "../../../../../../../../../../../../../../../../style-variables-colors.scss";

.c-EditModeControl {
  background-color: $white;
  font-size: 18px;
  outline: none;
  border: none;
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
}

.c-EditModeControl-red {
  color: $dark-red;
}

.c-EditModeControl-blue {
  color: $main-blue;
}
