.c-content-gate {
  position: relative;
  height: 100%;
  .c-spinner {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1000;

    display: flex;
    justify-content: center; /* align horizontal */
    align-items: center; /* align vertical */

    opacity: 0;

    -webkit-transition-timing-function: ease-in;
    transition-timing-function: ease-in;

    &.active {
      opacity: 1;
    }

    img {
      transition: opacity .1s;
    }
  }
}
