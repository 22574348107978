@import "../../../../../../../../../../../../style-variables-colors.scss";

.c-PrescriptionFillStatsCategory {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  overflow-y: scroll;

  >.unpacked-category {
    // padding: 12px;
    // border: solid 2px $light-grey;
    // border-radius: 8px;
    margin-bottom: 30px;

    &:last-of-type {
      margin-bottom: 0;
    }

    >h3 {
      margin-bottom: 12px;
    }

    >.stats-container {
      .parent-entity-container {
        display: flex;
        flex-direction: row;
        flex: 0 0 auto;
        padding: 30px 15px;
        border-bottom: solid 2px $light-grey;
        margin-bottom: 20px;

        &:last-of-type {
          border-bottom: none;
          margin-bottom: 0;
        }

        >.first-entity-container {
          display: flex;
          flex: 0 0 auto;
          width: 28%;

          >.first-entity-details {
            width: 80%;

            .first-entity-name {
              font-size: 20px;
              color: $main-blue;
              padding-right: 10px;
              height: max-content;
  
              img {
                width: 20px;
                height: 20px;
                margin-right: 12px;
                margin-bottom: -3px;
              }
            }

            >.first-entity-count {
              margin-left: 33px;
            }
          }
        }

        >.second-entities-container {
          display: flex;
          flex-direction: column;
          width: 100%;
          margin-top: -12px;
  
          .unpacked-second-entity {
            display: flex;
            flex-direction: row;
            flex: 0 0 auto;
            border: 2px solid $light-grey;
            border-radius: 4px;
            padding: 10px;
            margin-bottom: 12px;
  
            &:last-of-type {
              margin-bottom: 0;
            }
  
            >.second-entity-container {
              display: flex;
              flex: 0 0 auto;
              width: 65%;
  
              >.second-entity-details {
                width: 90%;
                >.second-entity-name {
                  font-size: 16px;
                  text-align: left;
                  padding: 3px 10px;
                  height: max-content;
                  font-weight: 600;
      
                  img {
                    width: 20px;
                    height: 20px;
                    margin-right: 12px;
                    margin-bottom: -3px;
                  }
                }
  
                >.second-entity-count {
                  margin-left: 42px;
                }
              }
            }

            .third-entities-container {
              display: flex;
              flex-direction: column;
              width: 100%;
      
              >.unpacked-third-entity {
                display: flex;
                flex-direction: row;
                flex: 0 0 auto;
                // justify-content: flex-end;
                margin-bottom: 10px;
      
                &:last-of-type {
                  margin-bottom: 0;
                }
      
                >.third-entity-container {
                  display: flex;
                  flex: 0 0 auto;
      
                  >.third-entity-details {
                    width: 100%;
      
                    >.third-entity-name {
                      font-size: 16px;
                      padding: 3px;
                      padding-left: 10px;
                      height: max-content;
                      font-weight: 600;
          
                      img {
                        width: 20px;
                        height: 20px;
                        margin-right: 12px;
                        margin-bottom: -3px;
                      }
                    }
      
                    >.third-entity-count {
                      margin-left: 42px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}