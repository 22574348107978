@import "../../../../../../../../../../style-variables-colors.scss";


.c-HealthcareSiteInventory {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  padding: 10px;
  overflow: hidden;

  >.count-statistics {
    margin-bottom: 20px;
  }
}
