@import "../../../../../../../../../../style-variables-colors.scss";


.c-KioskStorage {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  padding: 10px;
  overflow: hidden;

  >.content {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
  }
}
