@import "../../../../../../../../../../../../style-variables-colors.scss";


.c-KioskSlotsTable {
  display: flex;
  flex: 1 1 auto;

  .header-cell {
    margin-right: 4px;
  }

  .drug-name {
    padding-right: 10px;
  }

  .ndc {
    padding-right: 10px;
  }

  .numbered-item {
    text-align: right;
    padding-right: 10px;
  }
}

.threshold-in-bounds {
  color: $green;
}

.threshold-out-of-bounds {
  color: $dark-red;
}
