@import "../../../../../../../style-variables-colors.scss";

.v-NavbarView {
  display: flex;
  flex: 0 0 216px;
  position: relative;
  flex-direction: column;
  background-color: $white;
  color: #B6BEC2;
  overflow: hidden;
}
