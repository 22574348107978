.c-Button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  color: #FFFFFF;
  outline: none;
  border: none;
  text-decoration: none;

  &.blue {
    background-color: #6BA1CB;
  }

  &.red {
    background-color: #CF6059;
  }

  &.green {
    background-color: #4AA36C;
  }

  &.purple {
    background-color: #AF62E3;
  }

  &[disabled] {
    cursor: not-allowed;
    background-color: #CCCCCC;
  }
}
