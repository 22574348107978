@import '../../../../../../../../../../../../../../style-variables-colors.scss';


.c-PatientSuggestion {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: 100%;
  overflow: hidden;

  >.name {
    font-weight: 600;
    font-size: 18px;
  }

  >.detail {
    >.key {
      font-weight: 600;
      color: $text-mid-grey;
      margin-right: 10px;
    }
  }
}
