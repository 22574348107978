@import '../../../../../../../../../../../../style-variables-colors.scss';

.c-PatientDetails {
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  overflow: hidden;

  >.content {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    overflow-y: auto;
    padding: 5px 20px;

    >.photos-container {
      display: flex;
      flex-direction: column;
      flex: 0 1 auto;

      >.photos {

      }
    }
  }
}
