@import "../../../style-variables-colors.scss";

.c-DeleteConfirmationModal {
  flex: 1 1 auto;
  flex-direction: column;

  >.content {
    display: flex;
    flex: 1 1 auto;
    justify-content: center;
    align-items: center;
  }
}
