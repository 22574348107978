.c-AppFrameView {
  flex-direction: column;
  max-width: 1530px;
  margin-left: auto;
  margin-right: auto;
  border-left: solid 1px #E2E7E9;
  border-right: solid 1px #E2E7E9;
  box-shadow: 0 0 100px 20px rgba(0, 0, 0, 0.05);

  .sub-header-content {
    display: flex;
    flex: 1 1 0px;
    overflow: hidden;
  }
}

.primary-route-content-container {
  display: flex;
  flex: 1 1 auto;
  overflow: hidden;

  .primary-route-content {
    display: flex;
    flex-direction: column;
    flex: 1 1 100%;
    overflow: hidden;

    >div {
      flex: 1 0 100%;
    }
  }
}