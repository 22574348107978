@import '../../../../../style-variables-colors.scss';


.c-ReactDatePicker, .c-ReactDatePickerRequired {
  border: none;
  width: max-content;

  >.react-date-picker__wrapper {
    background: #ffffff;
    border: 2px solid $light-grey;
    border-radius: 3px;

    >.react-date-picker__inputGroup {
      >input {
        outline: none;

        &::placeholder {
          color: #cccccc;
        }
      }

      >span {
        outline: none;
        color: #757575;

        &::placeholder {
        }
      }
    }

    >.react-date-picker__clear-button {
      outline: none;
      >.react-date-picker__clear-button__icon {
        stroke: #cccccc;
      }
    }

    >.react-date-picker__calendar-button {
      outline: none;
      >.react-date-picker__calendar-button__icon {
        stroke: #cccccc;
      }
    }
  }
}

.c-ReactDatePickerRequired {
  >.react-date-picker__wrapper {
    border-color: $dark-red;
  }
}

.react-calendar__month-view__days__day--weekend {
  color: black;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}
