@import "../../../../../../../../../../../../../../../../style-variables-colors.scss";

$border-radius: 3px;

.c-ProgressBar {
  display: flex;
  flex: 1 1 auto;
  width: 100%;
  height: 15px;

  >.progress {
    background: $main-blue;
    -webkit-border-radius: $border-radius 0 0 $border-radius;
    -moz-border-radius: $border-radius 0 0 $border-radius;
    border-radius: $border-radius 0 0 $border-radius;
  }

  >.remaining {
    background: $light-grey;
    -webkit-border-radius: 0 $border-radius $border-radius 0;
    -moz-border-radius: 0 $border-radius $border-radius 0;
    border-radius: 0 $border-radius $border-radius 0;
  }
}
