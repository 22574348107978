@import "../../../../../../../../../../../../../../style-variables-colors.scss";


.c-Threshold {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  >.threshold {
    margin-right: 5px;
  }

  >.modifiers {
    display: flex;
    flex-direction: column;
    flex: 0 1 auto;

    >.modifier-icon {
      cursor: pointer;

      &:last-of-type {
        margin-top: 3px;
      }
    }
  }
}
