@import '../../../../../../../../../style-variables-colors.scss';

.c-ListTabs {
  display: flex;
  flex: 1 0 auto;
  justify-content: space-around;
  margin-top: 20px;
  background: $white;
  border: 1px solid $main-blue;
  border-radius: 3px;
  height: 35px;
  align-items: center;

  .tab {
    display: flex;
    flex: 1 1 auto;
    justify-content: center;
    align-items: center;
    height: 100%;
    border: 1px solid $main-blue;
    cursor: pointer;

    &.active {
      color: $white;
      background: $main-blue;
    }

    &.not-active {
      color: $main-blue;
      background: $white;
    }
  }
}
