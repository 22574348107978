@import '../../../../../../../../../../style-variables-colors.scss';

.c-LotInfo {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow-y: scroll;

  >.header {
    display: flex;
    flex: 0 0 auto;
    margin: 5px;
    color: $text-light-grey;
  }

  >.lot-info-container {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;

    >.data {
      display: flex;
      flex-direction: column;
      flex: 0 0 auto;
      padding: 5px 20px;
  
      >.data-row {
        display: flex;
        flex: 0 1 auto;
        justify-content: space-between;
        border-bottom: 1px solid $border-grey;
  
        >.name, >.name-row {
          padding: 15px;
          display: flex;
          flex-direction: row;
          align-items: center;
  
          >.icon {
            height: 20px;
            width: 20px;
            margin-right: 10px;
          }
  
          >.text {
            color: $main-blue;
          }
  
          >.text-red {
            color: $dark-red;
          }
        }
  
        >.name-row {
          display: flex;
          flex-direction: row;
          flex: 1 0 auto;
        }
  
        >.value {
          display: flex;
          align-items: center;
        }
  
        >.value-red {
          color: $dark-red;
        }
  
        >.value-column {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
  
          >.value {
            &:last-of-type {
              margin-bottom: 0;
            }
  
            margin-bottom: 3px;
          }
        }
      }
    }
  }

  >.drug-recalls-container {
    position: relative;
    display: flex;
    flex-direction: column;

    >.recall-prompt {
      position: absolute;
      height: 100%;
      width: 100%;
      background-color: white;
      display: flex;
      flex-direction: column;
      padding: 24px;

      >.header {
        font-size: 24px;
        text-align: center;

        >.lot-number {
          font-weight: 600;
        }
      }

      >.recall-options {
        display: flex;
        justify-content: space-evenly;
      }
    }

    >.drug-list-container {
      display: flex;
      flex: 1 1 auto;
      flex-direction: column;
      margin-top: 24px;
      
      >.header {
        display: flex;
        margin: 0px 24px;
        margin-bottom: 12px;
        font-size: 24px;

        >.title {
          flex: 1 1 auto;
        }

        >.header-actions {
          display: flex;
          flex: 0 0 auto;
        }
      }

      >.drugs-list {
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;

        >.drug-option-container {
          display: flex;
          flex: 0 0 auto;
          padding: 12px 24px;

          >.drug-info {
            display: flex;
            flex-direction: column;
            flex: 1 1 auto;
          }

          >.drug-actions {
            >.drug-action {

            }
          }
        }
      }
    }
  }
}
