@import "../../../../../../../../../../../style-variables-colors.scss";
@import "../../links-styles.scss";

$sub-route-padding-left: 5px;

.c-MultipleRoutesLink {
  display: flex;
  flex: 0 1 auto;
  flex-direction: column;
  width: 100%;

  >.sub-routes {
    display: flex;
    flex-direction: column;
    margin: 0 24px;

    >a {
      >.sub-route, >.sub-route-active {
        margin-bottom: 5px;
        padding-left: $sub-route-padding-left + $active-link-vertical-line-width;
      }

      >.sub-route-active {
        border-left: $active-link-vertical-line-width solid $green;
        padding-left: $sub-route-padding-left;

        >.name-active {
          color: $green;
        }
      }
    }
  }
}
