@import "../../../../../../../../../style-variables-colors.scss";
@import "../../notifications-pane-view-style.scss";


.c-Notifications {
  position: absolute;
  right: $notifications-pane-width;
  height: 100%;
  width: 300px;
  background-color: #F2F6F8;
  z-index: 100;
  border-left: 1px solid $light-grey;
  border-bottom: 1px solid $light-grey;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  overflow: hidden;

  /* Hide scroll bar */
  >div {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    padding-right: 17px; /* Increase/decrease this value for cross-browser compatibility */
    box-sizing: content-box; /* So the width will be 100% + 17px */
  }
}
