$transparent: rgba(204,169,44, 0);

@mixin pulse-animation($duration, $initial-color, $max-radius: 10px) {
  -webkit-animation: pulse $duration infinite;
  -o-animation: pulse $duration infinite;
  animation: pulse $duration infinite;

  @keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0 $initial-color;
      -o-box-shadow: 0 0 0 0 $initial-color;
      box-shadow: 0 0 0 0 $initial-color;
    }
    70% {
      -moz-box-shadow: 0 0 0 $max-radius $transparent;
      -o-box-shadow: 0 0 0 $max-radius $transparent;
      box-shadow: 0 0 0 $max-radius $transparent;
    }
    100% {
      -moz-box-shadow: 0 0 0 0 $transparent;
      -o-box-shadow: 0 0 0 0 $transparent;
      box-shadow: 0 0 0 0 $transparent;
    }
  }

  @-webkit-keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0 $initial-color;
      -o-box-shadow: 0 0 0 0 $initial-color;
      box-shadow: 0 0 0 0 $initial-color;
    }
    70% {
      -moz-box-shadow: 0 0 0 $max-radius $transparent;
      -o-box-shadow: 0 0 0 $max-radius $transparent;
      box-shadow: 0 0 0 $max-radius $transparent;
    }
    100% {
      -moz-box-shadow: 0 0 0 0 $transparent;
      -o-box-shadow: 0 0 0 0 $transparent;
      box-shadow: 0 0 0 0 $transparent;
    }
  }

  @-moz-keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0 $initial-color;
      -o-box-shadow: 0 0 0 0 $initial-color;
      box-shadow: 0 0 0 0 $initial-color;
    }
    70% {
      -moz-box-shadow: 0 0 0 $max-radius $transparent;
      -o-box-shadow: 0 0 0 $max-radius $transparent;
      box-shadow: 0 0 0 $max-radius $transparent;
    }
    100% {
      -moz-box-shadow: 0 0 0 0 $transparent;
      -o-box-shadow: 0 0 0 0 $transparent;
      box-shadow: 0 0 0 0 $transparent;
    }
  }

  @-o-keyframes pulse {
    0% {
      -moz-box-shadow: 0 0 0 0 $initial-color;
      -o-box-shadow: 0 0 0 0 $initial-color;
      box-shadow: 0 0 0 0 $initial-color;
    }
    70% {
      -moz-box-shadow: 0 0 0 $max-radius $transparent;
      -o-box-shadow: 0 0 0 $max-radius $transparent;
      box-shadow: 0 0 0 $max-radius $transparent;
    }
    100% {
      -moz-box-shadow: 0 0 0 0 $transparent;
      -o-box-shadow: 0 0 0 0 $transparent;
      box-shadow: 0 0 0 0 $transparent;
    }
  }
}
