@import "../../../../../../../../../../../../../style-variables-colors.scss";


.c-PrescriptionInfo {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;

  >.patient {
    font-size: 17px;
    margin-bottom: 5px;
  }

  >.drug {
    font-size: 15px;
    color: $text-light-grey;
  }
}
