@import '../../../../../../../../../../../../../../../../style-variables-colors.scss';


.c-PrescriptionStatusManipulation {
  display: flex;
  flex: 1 1 auto;
  justify-content: flex-end;
  min-height: 45px;
  max-height: 45px;

  >.button {
    height: 45px;
    width: 140px;
    font-size: 18px;
    margin-right: 20px;
    outline: none;
    border: none;
    cursor: pointer;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
  }

  >.process {
    color: $white;
    background: $main-blue;
  }

  >.process[disabled] {
    color: $light-grey;
    background: $white;
    border: 2px solid $light-grey;
    cursor: auto;
  }
}
