@import '../../../../../../../../style-variables-colors.scss';


.r-TechniciansView {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
}

.technician-view-icon-preview {
  border-radius: 50%;
  background: $light-grey;
}

.technician-view-icon-preview-large {
  border-radius: 50%;
  background: $light-grey;
  width: 65px;
  height: 65px;
  margin-right: 10px;
}