@import '../../../../../../../../../../../style-variables-colors.scss';


.c-FulfillmentDrugs {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;

  >.drug {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    padding: 10px 0;

    >.header {
      display: flex;
      flex: 0 0 auto;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 10px;
      align-items: center;

      >.title {
        display: flex;
        justify-content: center;
        align-items: center;

        >img {
          width: 20px;
          height: 20px;
          margin-right: 8px;
        }

        >.text {
          color: $main-blue;
          font-size: 20px;
          font-weight: 300;
        }
      }

      >.approve-reject-controls {
        display: flex;
        justify-content: space-between;

        >.button {
          width: 100px;
          height: 30px;
          font-size: 16px;
          margin-right: 10px;
          -webkit-border-radius: 3px;
          -moz-border-radius: 3px;
          border-radius: 3px;
          outline: none;

          &:hover {
            cursor: pointer;
          }

          &:last-of-type {
            margin-right: 0;
          }
        }

        >.approve {
          color: $white;
          background: $light-green;
          border: 2px solid $light-green;
        }

        >.reject {
          color: $dark-red;
          border: 2px solid $dark-red;
        }
      }
    }

    >.data {
      display: flex;
      flex: 0 1 auto;
      flex-direction: column;

      >.info {
        display: flex;
        flex: 0 0 auto;
        flex-direction: row;
        margin-bottom: 10px;

        >.full-name, >.dosage, >.rx-id {
          display: flex;
          flex: 1 1 auto;
          flex-direction: column;

          >.label {
            margin-bottom: 3px;
            color: $darker-grey;
            font-weight: 600;
          }

          >.info {

          }
        }

        >.full-name {
          width: 40%;
        }

        >.dosage {
          width: 30%;
        }

        >.rx-id {
          width: 30%;
        }
      }

      >.instructions, >.consultation-request-reason {
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;

        >.label {
          margin-bottom: 3px;
          color: $darker-grey;
          font-weight: 600;
        }

        >.info {

        }
      }

      >.instructions {
        margin-bottom: 10px;
      }
    }

    >.approve-reject-controls {
      display: flex;
      justify-content: space-between;

      >.button {
        width: 100px;
        height: 30px;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;
      }

      >.approve {
        color: $white;
        background: $light-green;
        border: 2px solid $light-green;
      }

      >.reject {
        color: $dark-red;
        border: 2px solid $dark-red;
      }
    }
  }
}
