@import "../../../../../../../../style-variables-colors.scss";


.c-HealthcareSitesColumn {
  display: flex;
  flex: 1 1 auto;
  flex-wrap: nowrap;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 1px;
    height: 1px;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.05);
  }

  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
  }

  >.healthcareSite-name {
    word-break: keep-all;
    white-space: nowrap;
    padding: 4px 12px;
    border-radius: 3px;
    background: $dark-red;
    color: $white;
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }
}
