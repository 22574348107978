@import "../../../../../../../../../../../../../style-variables-colors.scss";


.c-PendingPatientNotification {
  display: flex;
  flex: 0 0 auto;
  width: 100%;
  flex-direction: column;

  >.patient-name {

  }
}
