.c-DrillDownLayout {
  display: flex;
  flex: 1 1 auto;
  padding: 0;
  background-color: #F9FAFB;
  z-index: 10;
  overflow: hidden;

  .c-List {
    display: flex;
    flex-direction: column;
    flex: 0 0 438px;
  }
}
