@import '../../../../../style-variables-colors.scss';


>.c-Pages {
  display: flex;
  flex-direction: row;

  >.number, >.number-active {
    border: 1px solid $darker-grey;
    padding: 0 6px;
    vertical-align: center;
    color: $darker-grey;
    margin-right: 5px;
    border-radius: 3px;
    font-size: 13px;
    cursor: pointer;

    &:last-child {
      margin-right: 0;
    }
  }

  >.number-active {
    background: $light-grey;
  }

  >.dots {
    margin-right: 5px;
  }
}