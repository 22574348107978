@import "../../../../../../../../../../../style-variables-colors.scss";


.c-NotificationWrapper {
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  width: 100%;
  padding: 5px 10px;
  margin-bottom: 10px;

  >.notification {
    padding: 5px 0 5px 10px;
    border-bottom: 2px solid $main-blue;
  }
}

.c-NotificationWrapper-hoverable {
  &:hover {
    cursor: pointer;
    margin-bottom: 12px;

    >.notification {
      margin: -2px;
      border: 2px solid $main-blue;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      border-radius: 5px;
    }
  }
}
