.c-LineItem {
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  margin-bottom: 16px;

  .item-row {
    display: flex;
    flex: 0 0 auto;
    flex-direction: row;
    width: 100%;

    .item-column {
      display: flex;
      flex: 1 1 auto;
      flex-direction: column;
      padding: 0 20px;

      @media print {
        &:first-child {
          padding-left: 0px;
        }

        &:last-child {
          padding-right: 0px;
        }
      }

      .item-entry {
        display: flex;
        flex-direction: column;
        flex: 0 0 auto;
        font-weight: 400;
        padding-top: 0px;
        

        &.emphasize {
          font-weight: 600;
          font-size: 24px;
          padding-top: 0px;
        }

        .item-key {
          font-weight: 600;
          font-size: 20px;
          color: #555555;
        }

        .item-value {
          display: flex;
          flex: 0 0 auto;
          // font-size: 24px;
        }
      }
    }
  }

  >.line-item-content {
    display: flex;
    flex-direction: column;
    flex: 0 0 auto;
    font-size: 20px;

    @media print {
      font-size: 16px;
    }

    >.drug-name {
      flex: 0 0 auto;
      margin-right: 30px;
    }

    >.horizontal-rule {
      flex: 1 1 auto;
      margin-top: 8px;
      margin-bottom: 8px;

      &.dispense {
        border-top: 3px solid #6BA1CB;
      }

      &.mail-order {
        border-top: 3px solid #AF62E3;
      }

      &.declined {
        border-top: 3px solid #CF6059;
      }
    }

    >.cost {
      margin-left: 76px;
    }

    &.strikethrough {
      text-decoration: line-through;
      color: #CCCCCC;
      font-weight: 400;
    }
  }

  >.line-item-tag {
    font-size: 20px;

    &.dispense {
      color: #6BA1CB;
    }

    &.mail-order {
      color: #AF62E3;
    }

    &.declined {
      color: #CF6059;
    }
  }
}
