@import '../../../../../../../../../style-variables-colors.scss';


.c-FulfillmentInfo {
  display: flex;
  flex: 1 0 auto;
  //height: 50%;
  flex-direction: column;
  padding: 0 20px;
}
