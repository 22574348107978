@import "../../../../../../../../../../../../style-variables-colors.scss";

.c-KioskStatusCaptionValue {
  display: flex;
  flex: 0 1 auto;
  flex-direction: column;
  margin-right: 50px;

  &:last-of-type {
    margin-right: 0;
  }

  >.caption {
    font-size: 14px;
    color: $grey;
    font-weight: 600;
    text-transform: uppercase;
  }

  >.value {
    font-size: 22px;
    font-weight: 600;
    word-wrap: break-word;
  }

  >.green {
    color: $green;
  }

  >.red {
    color: $dark-red;
  }
}