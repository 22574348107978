.SmartTableContent {
  min-width: 100%;
  background-color: white;

  .content-cell {
    box-sizing: border-box;
    display: inline-block;
    padding: 10px 0px;
    vertical-align: middle;
    white-space: normal;
  }

  .checkbox {
    vertical-align: middle;
    width: 19px;
    height: 19px;
  }

  .select-unavailable-caption {
    min-width: 10%;
  }
}

.content-row {
  &--withClickable {
    cursor: pointer;
    &:hover {
      background-color: #D6F0FF;
      .content-cell-button {
        visibility: visible;
      }
    }
  }

  &-active {
    background-color: #D6F0FF;
  }
}