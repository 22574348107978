@import '../../../../../../../../../../../../style-variables-colors.scss';

.c-PatientDetails {
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  overflow: hidden;

  >.content {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    overflow-y: auto;
    padding: 5px 20px;

    >.c-content-gate {
      >.pharmacist-action-container {
        display: flex;
        flex-direction: column;
        flex: 0 1 auto;
        padding-bottom: 10px;
        margin-bottom: 20px;
        border-bottom: 1px solid $border-light;
  
        >.title {
          font-size: 20px;
          margin-bottom: 5px;
        }
  
        >.action-description {
          font-size: 18px;
  
          >.action {
            font-weight: 600;
          }
        }
      }
    }

  }
}
