@import '../../../../../../../../../../../style-variables-colors.scss';


.c-PatientInfo {
  display: flex;
  flex: 0 1 auto;
  flex-direction: column;
  padding-top: 20px;
  margin-bottom: 20px;

  >.header {
    display: flex;
    flex: 0 0 auto;
    flex-direction: row;
    margin-bottom: 10px;
    align-items: center;

    >img {
      width: 20px;
      height: 20px;
      margin-right: 8px;
    }

    >.text {
      color: $main-blue;
      font-size: 20px;
      font-weight: 300;
    }
  }

  >.data {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;

    >.row {
      display: flex;
      flex: 1 1 auto;
      width: 100%;
      margin-bottom: 10px;

      :last-child {
        margin-bottom: 0;
      }

      >.key-value {
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;
        width: 100%;

        >.label {
          color: $darker-grey;
          font-weight: 600;
          margin-bottom: 3px;
        }
      }

      >.full-name {
        margin-bottom: 10px;
      }
  
      >.allergies {
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;
        width: 100%;
        align-items: flex-start;
  
        >.label {
          margin-bottom: 6px;
          color: $darker-grey;
          font-weight: 600;
        }
  
        >.info {
          display: flex;
          flex: 1 1 auto;
          flex-direction: row;
          flex-wrap: wrap;
          align-content: flex-start;
  
          >.allergy {
            padding:  4px 7px;
            background: $allergies-color;
            border-radius: 5px;
            height: max-content;
            margin-right: 5px;
            color: $white;
            margin-bottom: 5px;
          }
        }
      }
    }
  }
}
