@import "../../../../../../../../../style-variables-colors.scss";
@import "../../notifications-pane-view-style.scss";


/* SLIDE IN */
.notifications-slide-in-animation {
  white-space:nowrap;

  -webkit-animation: slide-in .4s ease;
  -moz-animation: slide-in .4s ease;
  animation: slide-in .4s ease;

  -webkit-animation-duration: .4s;
  -moz-animation-duration: .4s;
  -o-animation-duration: .4s;
  animation-duration: .4s;
}

@keyframes slide-in {
  0% {
    transform: translateX(10%);
    width: 10%;
  }
  100% {
    transform: translateX(0);
  }
}


/* SLIDE OUT */
.notifications-slide-out-animation {
  white-space:nowrap;

  -webkit-animation: slide-out .4s ease;
  -moz-animation: slide-out .4s ease;
  animation: slide-out .4s ease;

  -webkit-animation-duration: .4s;
  -moz-animation-duration: .4s;
  -o-animation-duration: .4s;
  animation-duration: .4s;
}

@keyframes slide-out {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(10);
    width: 10%;
  }
}
