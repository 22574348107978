@import '../../../../style-variables-colors.scss';

$time-width: 66px;
$icon-width: 59px;


.c-appCommandsLog {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  padding: 0 10px;
  overflow: hidden;
  position: relative;

  >.no-data-text {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    >.text {
      width: 80%;
      text-align: center;
    }
  }

  >.list-body {
    display: flex;
    overflow-y: auto;
    flex: 1 1 auto;
    flex-direction: column;

    >.date-header {
      display: flex;
      flex-direction: row;
      flex: 0 0 auto;
      margin-bottom: 10px;

      >.date {
        color: $main-blue;
        flex: 0 0 auto;
      }

      >.line {
        background: $main-blue;
        flex: 1 0 auto;
        height: 1px;
        align-self: center;
        margin-left: 10px;
      }

    }

    >.data {
      display: flex;
      flex-direction: row;
      flex: 0 0 auto;


      >.time {
        display: flex;
        flex: 0 0 auto;
        width: $time-width;
        color: $lighter-grey;
      }

      >.icon {
        display: flex;
        flex-direction: column;
        flex: 0 0 auto;
        align-items: center;

        width: $icon-width;

        >.image-wrapper {
          display: flex;
          border: 1px solid $border-grey;
          border-radius: 50%;
          width: 25px;
          height: 25px;
          justify-content: center;
          align-items: center;

          >.image {
            flex:0 0 auto;
            width: 15px;
            height: 15px;
          }
        }

        >.bottom-line {
          flex: 1 0 auto;
          width: 1px;
          background: $border-grey;
        }
      }

      >.main-data {
        display: flex;
        flex-direction: column;
        flex: 1 0 auto;

        >.data {
          display: flex;
          flex-direction: row;
          flex: 0 0 auto;
          justify-content: space-between;
          padding-right: 10px;

          >.type {
            display: flex;
            flex-direction: row;
            flex: 0 0 auto;
          }

          >.key-value-block {
            display: flex;
            flex-direction: column;
            flex: 0 0 auto;

            >.value {

            }

            >.key {
              color: $lighter-grey;
            }
          }
        }

        >.bottom-line {
          flex: 0 0 auto;
          height: 1px;
          background: $border-grey;
          margin: 10px 0;
        }
      }
    }
  }
}
