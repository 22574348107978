@import '../../../../../../../style-variables-colors.scss';

.c-ListItem {
  display: flex;
  flex: 0 0 auto;

  .item-key-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 1 30px;
    max-width: 75px;

    .item-key {
      font-size: 14px;
      color: #B6BEC2;
      min-width: 60px;
      margin-right: 10px;

      &.active {
        color: $main-blue;
      }
    }

  }

  .list-item {
    display: flex;
    flex: 1 1 auto;
    padding: 24px;
    background-color: #FFFFFF;
    border-radius: 4px;
    border: solid 2px #E2E7E9;
    cursor: pointer;

    >.icon-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 0 0 24px;
      margin-right: 12px;

      >.icon {
        width: 20px;
        height: 20px;

        img {
          width: 100%;
          height: 100%;
        }

        &:last-child {
          margin-bottom: 5px;
        }
      }
    }

    >.content-container {
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;

      >.title-container {
        display: flex;
        flex: 0 0 auto;
        justify-content: space-between;

        >.title-icon {
          flex: 0 0 24px;
          width: 24px;
          height: 24px;
          position: relative;
          margin-right: 12px;
        }

        >.title {
          font-size: 20px;
          line-height: 24px;
          word-break: break-word;
          color: #333333;
        }
      }

      >.body-container {
        flex: 0 0 auto;
        margin-top: 12px;
      }
    }

    &.active {
      border: solid 2px #59A3CF;

      >.content-container {
        >.title-container {
          >.title {
            color: #59A3CF;
          }
        }
      }
    }
  }

}
