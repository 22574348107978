.c-DrugsTable {
  .content-cell {
    padding: 10px 8px;
    
    .drug-name, .drug-strength {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
  
}
