@import '../../../../../../../style-variables-colors.scss';


.c-InfoCard {
  display: flex;
  flex: 1 0 0;
  flex-direction: column;
  border: 1px solid $light-grey;
  border-radius: 3px;
  padding: 10px;
  height: 100%;
  margin: 0 20px;
  overflow-x: auto;

  .item {
    display: flex;
    margin-bottom: 10px;

    .key {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin-right: 10px;
      color: $darker-grey;
      text-transform: uppercase;

      img {
        width: 20px;
        height: 20px;
      }
      .title {
        margin-top: 5px;
      }
    }


    .value-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      height: 100%;
      width: 100%;

      .value {
        word-wrap: break-word;
      }

      >.value-red {
        color: $dark-red;
      }

      >.value-green {
        color: $green;
      }

      .title {
        color: $main-blue;
        font-size: 20px;
      }
    }
  }

  &:first-child, &:last-child {
    margin: 0;
  }
}
