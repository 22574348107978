.c-HealthcareSites {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;

  >.healthcareSites {
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    overflow-y: auto;
    max-height: 125px;

    &::-webkit-scrollbar {
      width: 1px;
      height: 1px;
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.05);
    }

    &::-webkit-scrollbar-thumb {
      background-color: darkgrey;
      outline: 1px solid slategrey;
    }
  }
}
