@import '../../../../../../../../../../../../style-variables-colors.scss';

.c-PatientDetailItem {
  display: flex;
  flex: 0 1 auto;
  justify-content: space-between;
  border-bottom: 1px solid $border-grey;

  >.value {
    display: flex;
    align-items: center;
  }

  >.input-container {
    display: flex;
    flex: 1 1 auto;
    justify-content: flex-end;
    padding: 10px;
    
    >input {
      width: 100%;
      padding: 5px;
      text-align: right;
      border: 1px solid $border-grey;
      border-radius: 3px;
      outline: none;
    }
  }
}
