.c-PrescriptionFillEditModalView {

  .left-area {
    display: flex;
    flex-direction: column;
    flex: 1 1 50%;
    margin-right: 10%;

    .drug {
      .drug-name {
        font-size: 20px;
      }

      .drugs-autocomplete {
        margin-top: 14px;

        .suggestion-container {
          &:hover {
            background-color: #F4F4F4;
          }
        }
      }
    }
  }
}