@import "../../../../../../../../../../../style-variables-colors.scss";
@import "../../../../notifications-pane-view-style.scss";
@import "./animations.scss";

$notification-item-padding: 15px;

.c-NotificationPreview {
  display: flex;
  flex: 0 1 $notifications-pane-width;
  border-bottom: 1px solid $light-grey;
  position: relative;
  cursor: pointer;

  >img {
    width: $notifications-pane-width;
    height: $notifications-pane-width;
    padding: $notification-item-padding;
  }

  >.counter {
    position: absolute;
    top: 2px;
    right: 2px;
    padding: 1px 5px;
    font-size: 10px;
    background: $dark-red;
    color: $white;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
  }

  >.red-circle {
    color: $dark-red;
    border-radius: 50px;
    width: 12px;
    height: 12px;
  }

  >.new-notification-animation {
    @include ring-animation(4s);
  }
}
