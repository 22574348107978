@import '../../../../../../style-variables-colors.scss';


.c-ListInput {
  >.input-wrapper {
    display: flex;
    flex: 0 0 auto;
    flex-direction: row;

    margin-bottom: 5px;

    >.input, >.input-required {
      height: 30px;
      width: 100%;
      padding: 1px 5px;
      font-size: 14px;
      border: 2px solid $light-grey;
      border-radius: 5px;
      outline: none;
    }

    >.input-required {
      border-color: $dark-red;
    }

    >.add-button {
      border: 2px solid $light-grey;
      font-size: 15px;
      border-radius: 5px;
      outline: none;
      cursor: pointer;
      color: $grey;
    }
  }

  >.value-wrapper {
    display: flex;
    flex: 0 0 auto;
    flex-direction: row;
    flex-wrap: wrap;

    >.element {
      display: flex;
      flex: 0 0 auto;
      flex-direction: row;

      border: 1px solid grey;
      width: max-content;
      border-radius: 5px;
      margin-right: 10px;
      margin-bottom: 5px;
      background: $white;

      >.name {
        padding: 4px;
      }

      >.remove-button {
        background: none;
        border: none;
        outline: none;
        font-size: 14px;
        cursor: pointer;
      }
    }
  }
}
