@import '../../../../../../../../../../style-variables-colors.scss';

.c-TechnicianContact {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;

  >.header {
    display: flex;
    flex: 0 0 auto;
    margin: 5px;
    color: $text-light-grey;
  }

  >.data {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    padding: 5px 20px;

    >.data-row {
      display: flex;
      flex: 0 1 auto;
      justify-content: space-between;
      border-bottom: 1px solid $border-grey;

      >.name, >.name-row {
        padding: 15px;
        display: flex;
        flex-direction: row;
        align-items: center;

        >.icon {
          height: 20px;
          width: 20px;
          margin-right: 10px;
        }

        >.text {
          color: $main-blue;
        }
      }

      >.name-row {
        display: flex;
        flex-direction: row;
        flex: 1 0 auto;
      }

      >.value {
        display: flex;
        align-items: center;
      }

      >.value-column {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        >.value {
          &:last-of-type {
            margin-bottom: 0;
          }

          margin-bottom: 3px;
        }
      }
    }
  }
}
