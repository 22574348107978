$border-color: #CCCCCC;
$border-radius: 5px;
$new-section-padding: 40px;

.c-ReceiptView {
  display: flex;
  flex: 1 1 100%;
  flex-direction: column;
  height: 100%;
  margin-right: -32px;

  .actions-area {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media print {
      display: none !important;
    }
  }

  @media print {
    visibility: visible;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    bottom: 0;
    right: 0;
  }

  >.content {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding-right: 32px;
    overflow-y: scroll;

    @media print {
      padding: 0;
    }

    >h3 {
      &.mega-title {
        color: #000000;
        font-size: 28px;
        margin-bottom: 48px;
        text-align: center;

        @media print {
          font-size: 24px;
          margin-bottom: 10px;
        }
      }
    }

    >.columned-data {
      display: flex;
      flex-direction: row;
      margin-bottom: 12px;
      gap: 24px;

      @media print {
        gap: 8px;
        margin-bottom: 0px;
      }

      &.thin {
        padding-left: 80px;
        padding-right: 80px;
      }

      >.column {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;

        >.column-item {
          display: flex;
          flex-direction: column;
          flex: 0 0 auto;
          font-size: 20px;
          border-bottom: solid 1px $border-color;
          padding: 10px 20px;
          padding-top: 0px;

          @media print {
            padding: 10px 0;
            font-size: 16px;
          }
        }
      }
    }

    .item-key {
      font-weight: 600;
    }

    >.fulfillment-details {
      display: flex;
      font-size: 24px;
      flex-direction: column;
    }

    >.costs-summary {
      display: flex;
      flex-direction: column;
      flex: 0 0 auto;
      align-items: flex-end;
      font-size: 24px;
      margin-bottom: 42px;
      font-weight: 400;

      @media print {
        margin-bottom: 12px;
      }

      >.cost-summary-element {
        display: flex;
        flex-direction: row;
        flex: 0 0 auto;
        padding-right: 20px;

        @media print {
          padding-right: 0px;
        }

        >.key {
          display: flex;
          flex: 0 0 auto;
          justify-content: flex-end;
          align-items: flex-end;
        }

        >.value {
          display: flex;
          flex: 0 0 162px;
          width: 162px;
          justify-content: flex-end;
          font-size: 28px;
        }

        &.bold {
          font-weight: 600;
        }
      }
    }

    >.declined-area {
      display: flex;
      flex-direction: column;
      flex: 0 0 auto;
      padding-top: 32px;
      border-top: solid 2px #CF6059;
    }
  }
}
