.prescription-element {

  .title-container {
    display: flex;
    flex-direction: row;

    .icon {
      margin-right: 10px;
      width: 20px;
      height: 20px;
      align-self: center;
    }

    .title {
      height: 100%;
      text-align: left;
    }
  }

  .data {
    padding: 0 8px 8px 0;
  }
}
