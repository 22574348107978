@import "../../../../../../../../../../style-variables-colors.scss";

.active-module-template-view {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  padding: 10px;
  height: 100%;
  width: 100%;

  &-header {
    display: flex;
    flex-direction: row;
    flex: 0 0 auto;
    justify-content: space-between;

    .module-template-control-buttons {
      display: flex;
      flex-direction: row;

      &-submit-button {
        width: max-content;
        height: max-content;
        padding: 15px;
        background: $main-blue;
        color: $spec-white;
        outline: none;
        border: none;
      }
    }

    &-module-template-name {
      color: $main-blue;
      font-size: 30px;
    }
  }

  &-data {
    display: flex;
    flex-direction: row;
    flex: 1 1 auto;
    border: 1px solid $light-grey;
    height: 100%;

    .module-template-silhouette {
      display: flex;
      -ms-flex-direction: column;
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
      justify-content: center;
      align-items: center;
      padding-top: 10px;
      padding-left: 10px;
    }

    .modules-manipulation-bar {
      display: flex;
      flex-direction: column;
      width: 330px;

      .slots-header-container {
        display: flex;
        justify-content: space-between;
        flex: 0 0 auto;
        margin-bottom: 15px;
        max-height: 30px;
        padding: 0 10px;

        .header {
          font-size: 22px;
          color: $main-blue;
          align-self: center;
        }

        .add-slot-btn {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 110px;
          font-size: 16px;
          color: $white;
          background-color: $main-blue;

          .plus-sign, .text {
            padding: 5px 10px;
          }

          .plus-sign {
            font-weight: 700;
          }

          .text {
          }
        }
      }

      .slots-list {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        margin-top: 15px;
        padding: 0px 10px;
        overflow-y: auto;
      }

      .module-template-main-info {
        display: flex;
        flex-direction: column;
        flex: 0 0 auto;
        margin-bottom: 15px;
        padding: 10px 10px 0 10px;

        input {
          width: 100%;
          height: 30px;
          font-size: 17px;
          padding: 5px;
          outline: none;
        }

        .name-info {
          margin-bottom: 10px;

          .name-info-label {
            font-weight: lighter;
          }

          .name-input {
          }
        }

        .height-width-info {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          .height-info {
            display: flex;
            flex-direction: column;
            width: 40%;

            .height-info-label {
              font-weight: lighter;
            }

            .height-input {
            }
          }

          .width-info {
            display: flex;
            flex-direction: column;
            width: 40%;

            .width-info-label {
              font-weight: lighter;
            }

            .width-input {
            }
          }
        }
      }
    }
  }
}
