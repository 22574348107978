@import '../../../../style-variables-colors.scss';


.c-Statistic {
  display: flex;
  flex-direction: row;
  flex: 0 0 auto;
  border-bottom: 1px solid $border-grey;

  >.statistic-block {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    padding: 10px;
    border-right: 1px solid $border-grey;
    justify-content: space-between;

    >.header, >.header-none {
      display: flex;
      justify-content: flex-end;

      >.data {
        border-radius: 15px;
        padding: 1px 14px;
        font-size: 11px;
        border: 1px solid;
      }

      >.data-green {
        border: $lighter-green 1px solid;
        color: $lighter-green;
      }

      >.data-red {
        color: $pink-red;
        border-color: $pink-red;
      }
    }

    >.header-none {
      height: 20px;
    }

    >.content {
      color: $main-blue;
      padding: 20px;
      text-align: center;
      font-weight: 300;
    }

    >.footer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      >.name {
        text-transform: uppercase;
        color: $lighter-grey;
        font-size: 12px;
      }

      >.image {
        width: 18px;
        height: 18px;
      }
    }
  }

  >.statistic-block:last-child {
    border-right: none;
  }
}
