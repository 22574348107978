@import "../../../../../../../../../style-variables-colors.scss";

.c-UserTypeSwitch {
  display: flex;
  flex: 0 1 auto;
  width: 90%;
  height: 35px;
  border: 2px solid $main-blue;
  border-radius: 3px;

  position: absolute;
  left: 0;
  right: 0;
  bottom: 20px;
  margin: 0 auto;

  >.switch-user-button {
    width: 50%;
    color: $main-blue;
    background: $white;
    outline: none;
    border: none;
    font-size: 15px;
    cursor: pointer;
  }

  >.active {
    color: $white;
    background: $main-blue;
  }
}
