@import "../../../../../../../../../../style-variables-colors.scss";

$prescription-wrapper-margin-right: 10px;


.prescription-wrapper {
  width: 100%;
  max-width: 100%;
  min-width: 45%;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  justify-content: space-between;
  margin: 10px $prescription-wrapper-margin-right 0px 20px;
  overflow-y: auto;

  .prescription-element {
    .data {
      width: 100%;
    }
  }

  .columns {
    display: flex;

    .column {
      flex: 0 0 250px;
    }
  }

  .header {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    margin-bottom: 20px;

    .patient-name-title {
      font-size: 24px;
      color: $main-blue;
    }

    .rx-id {
      font-size: 18px;
      color: $darker-grey;
    }
  }

  .prescription-tabs {
    display: flex;
    flex-direction: row;
    flex: 1 0 35px;
    margin-bottom: -1px;

    .tab {
      margin-right: 20px;
      color: $grey;
      border: 1px solid $light-grey;
      padding: 4px;
    }

    .tab-active {
      color: $main-blue;
      border-bottom: 1px solid $spec-white !important;
      z-index: 1;
      border-top: 3px solid $main-blue;
      padding-top: 2px;
      padding-bottom: 6px;
    }
  }

  .row-right {
    display: flex;
    align-content: center;
    justify-content: flex-end;

    >.button-expand {
      height: 25px;
      width: 80px;
      padding: 3px 4px;
      margin-right: $prescription-wrapper-margin-right;
      font-size: 14px;
      color: $white;
      background: $main-blue;
      border: 1px solid $main-blue;
      border-radius: 3px;
      outline: none;
      cursor: pointer;
    }
  }

  .prescription-info-wrapper {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;

    &.expanded {
      flex-direction: row;
    }

    .prescription-info {
      overflow-y: auto;
      border: 1px solid $light-grey;
    }

    .container-label {
      .tile-viewport {
        height: auto !important;
        img {
          height: auto !important;
          width: 100%;
        }
      }
    }

    .container-images {
      .tile-viewport {
        height: 100px !important;
        img {
          height: 100px !important;
        }
      }
    }

    .prescription-model-info {
      flex: 1 1;
      flex-basis: 0px;
    }

    .prescription-image-info {
      flex: 1 1;
      flex-basis: 0px;
    }
  }

  .data-row, .data-row-no-padding {
    margin: 15px 0px;
    padding: 0px 10px;
    justify-content: space-between;

    .contact {
      border-radius: 4px;
      border: solid 1px $main-blue;
      padding: 10px;
      margin: 2px 0;
    }

    .blue-header {
      text-align: left;
      margin-bottom: 10px;
      font-size: 18px;
      color: $main-blue;
    }
  }

  .data-row-no-padding {
    padding: 0;
  }

  .table {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    text-align: center;
  }

  .table-cell {
    width: 100%;
    box-sizing: border-box;
    flex-grow: 1;
    padding: 5px;
    overflow: hidden;
    list-style: none;

    .value {
      font-weight: 700;
    }
  }

  .table-2cols > .table-cell  { width: 50%; }
  .table-3cols > .table-cell  { width: 33.33%; }
  .table-4cols > .table-cell  { width: 25%; }
  .table-5cols > .table-cell  { width: 20%; }
  .table-6cols > .table-cell  { width: 16.6%; }

  .border-top-left {
    border-left: 1px solid $darker-grey;
    border-top: 1px solid $darker-grey;
  }

  .border-right-top {
    border-right: 1px solid $darker-grey;
    border-top: 1px solid $darker-grey;
  }

  .border-left-top-right {
    border-right: 1px solid $darker-grey;
    border-left: 1px solid $darker-grey;
    border-top: 1px solid $darker-grey;
  }

  .border-solid {
    border: 1px solid $darker-grey;
  }

  .border-top-left-round {
    border-top-left-radius: 4px;
  }

  .border-top-right-round {
    border-top-right-radius: 4px;
  }

  .border-bottom-left-round {
    border-bottom-left-radius: 4px;
  }

  .border-bottom-right-round {
    border-bottom-right-radius: 4px;
  }

  .bottom {
    text-align: right;
    margin-bottom: 20px;
    min-height: 30px;

    .buttons {
      width: 100%;
      border: none;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      .button {
        width: 120px;
        height: 30px;
        font-size: 15px;
        color: $white;
        border: none;
        border-radius: 4px;

        &:hover {
          font-size: 17px;
        }
      }

      .approve {
        background-color: $green;
        margin-right: 30px;
      }

      .reject {
        background-color: $pink-red;
      }
    }

    .status {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      font-size: 18px;
      text-transform: uppercase;

      .value {
        margin-left: 7px;
      }

      .approved {
        color: $green;
      }

      .rejected {
        color: $pink-red;
      }
    }
  }
}
