.c-PrescriptionFulfillmentView {
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF;
  padding: 48px 48px;
  min-width: 1000px;
  height: calc(100vh - 128px);

  section {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    margin-top: 48px;
    border-top: solid 1px #CCCCCC;
    padding-top: 24px;

    > h2 {
      margin-bottom: 8px;
      // color: #6BA1CB;
    }
  }

  .fulfillment-details {
    display: flex;
    position: relative;
    flex-direction: column;
    flex: 1 1 auto;
    overflow: hidden;
    margin-right: -32px;
  }

  .row-area {
    display: flex;
    flex-direction: row;
    flex: 0 0 auto;
    gap: 24px;

    .column-area {
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
      gap: 16px;
    }
  }

  .consultation-area {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;

    h3 {
      margin-bottom: 12px;
    }

    .consultation-container {
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;

      border: solid 1px #CCCCCC;
      padding: 16px;
    }
  }

  .signature-area {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    
    width: 100%;

    h3 {
      flex: 0 0 auto;
      margin-bottom: 12px;
    }

    .signature-container {
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
      position: relative;
      align-items: center;
      padding-top: 12px;
      background-color: #FFFFFF;
      border: solid 1px #CCCCCC;
      padding: 16px;
      gap: 16px;
      overflow: hidden;

      .statement {
        display: flex;
        flex: 0 0 auto;
        text-align: center;
        max-width: 400px;
      }

      img.signature-image {
        background-color: #FFFFFF;
        width: 400px;
      }
    }
  }

  .footer-area {
    display: flex;
    flex: 0 0 auto;
    padding-top: 24px;
  }

  .content {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    overflow-y: auto;
    padding-right: 32px;
  }

  .green-text {
    color: #4AA36C;
  }

  .red-text {
    color: #CF6059;
  }

  .blue-text {
    color: #6BA1CB;
  }

  .header {
    display: flex;

    .content-area {
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
    }
  }

  .content-area {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    align-items: flex-start;

    .content-row-container {
      display: flex;
      flex-direction: row;
      gap: 16px;
    }
  }

  .prescription-fills-area {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 24px;

    .prescription-fill-container {
      display: flex;
      padding: 16px;
      border: solid 1px #CCCCCC;
      gap: 16px;

      .strikethrough {
        text-decoration: line-through;
        color: #CCCCCC;
      }

      .token {
        flex: 0 0 auto;
        font-size: 14px;
        font-weight: 600;
        border-radius: 5px;
        padding: 2px 8px;
      }

      .green-token {
        color: #4AA36C;
        border: solid 1px #4AA36C;
      }

      .blue-token {
        color: #6BA1CB;
        border: solid 1px #6BA1CB;
      }

      .purple-token {
        color: #AF62E3;
        border: solid 1px #AF62E3;
      }

      .red-token {
        color: #CF6059;
        border: solid 1px #CF6059;
      }
    }

    .price-history {
      display: flex;
      flex-direction: column;
      margin-top: 8px;
      align-items: flex-end;
      width: 100%;

      .price-history-entry {
        display: flex;
        flex: 0 0 auto;
        width: 100%;
        margin-bottom: 0px;
        border-bottom: solid 1px #CCCCCC;

        &:last-child {
          border-bottom: none;
        }

        .info {
          flex: 1 1 auto;
          display: flex;
          flex-direction: column;
          align-items: flex-end;

          .date {
            font-size: 12px;
          }
        }

        .price {
          flex: 0 0 80px;
          text-align: right;
        }
      }
    }
  }

  .payment-area {
    display: flex;
    flex-direction: column;

    .payment-entry {
      display: flex;
      flex: 0 0 auto;
    }

    .payment-status {

    }
  }

  .actions-area {
    display: flex;
    flex-direction: row;
    gap: 16px;
    margin-top: 24px;
  }
}
