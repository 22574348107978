@import "../../../../../../../../../../style-variables-colors.scss";

.c-PrescriptionInfoBlock {
  margin-bottom: 20px;

  >.title {
    font-size: 26px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  >.data {
    display: flex;
    flex-wrap: wrap;
    font-size: 18px;

    >div {
      margin-right: 20px;
    }

    >div:last-child {
      margin-left: 0;
    }
  }

  >.c-content-gate {
    >.no-data-available {
      font-size: 18px;
    }
  }
}
