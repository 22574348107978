@import "../../../style-variables-colors.scss";
@import "../../app/root/routes/app-frame/views/header/header-view.scss";

.ModalOverlay {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);

  display: flex;
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;
  outline: none;

  &-under-header {
    top: $header-height;
  }

  >.c-Modal {
    position: absolute;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    height: 90%;
    width: 90%;
    padding: 20px;
    border-radius: 5px;
    background: $white;
    outline: none;

    >.header {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin-bottom: 30px;
      font-size: 32px;

      >.title-container {
        display: flex;
        text-align: center;
        flex: 1 1 auto;

        >.title {
          flex: 1 1 auto;
          padding: 0 25px;
          font-weight: 600;
          color: $text-mid-grey;
        }
      }

      >.close-modal-icon-container {
        margin-left: auto;
        right: 25px;
        top: 10px;
        position: absolute;
        font-size: 28px;
        color: $text-light-grey;

        >.close-modal-icon {
          cursor: pointer;
        }
      }
    }
  }
}
