@import "../../../../../../../style-variables-colors.scss";

.c-TechnicianWorkPlaces {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: 100%;
  overflow: hidden;

  >.search-container {
    position: relative;
    margin-bottom: 20px;

    >.auto-suggest {
      position: absolute;
      background: $white;
      width: 100%;
      border: 1px solid $light-grey;
      border-top: none;
      -webkit-border-radius: 3px 3px 5px 5px;
      -moz-border-radius: 3px 3px 5px 5px;
      border-radius: 3px 3px 5px 5px;

      display: flex;
      flex-direction: column;
      max-height: 200px;
      overflow-y: auto;

      >.work-place {
        font-size: 18px;
        padding: 5px 5px 8px;
        border-bottom: 1px solid $light-grey;

        &:last-of-type {
          padding-bottom: 0;
          border-bottom: none;
        }

        &:hover {
          cursor: pointer;
          background: $light-grey;
        }
      }
    }
  }

  >.work-places {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    overflow-y: auto;

    >.work-place {
      display: flex;
      flex: 0 1 auto;
      justify-content: space-between;
      padding-right: 10px;
      margin-bottom: 10px;

      &:last-of-type {
        margin-bottom: 0;
      }

      >.remove-icon {
        cursor: pointer;
      }
    }
  }
}
