@import "../../../../../style-variables-colors.scss";

$video-square-size: 500px;

.c-selectWebCamModal {
  flex: 0 0 auto;
  flex-direction: column;
  align-items: center;
  margin: auto;
  background: $white;
  outline: none;
  position: relative;
  overflow: hidden;

  >.close-icon {
    position: absolute;
    right: 10px;
    top: 2px;
    cursor: pointer;
    font-size: 18px;
  }

  >.scroll-wrapper {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    overflow-y: auto;
    width: 90%;

    >.camera-selection-block {
      display: flex;
      flex: 0 0 auto;
      flex-direction: column;
      align-items: center;
      width: 100%;
      border-bottom: 2px solid $light-grey;

      >.header {
        padding: 10px;
        font-size: 25px;
        width: 70%;
        text-align: center;
      }

      >.devices-drop-down {
        padding: 3px;
        font-size: 15px;
        border: 1px solid $main-blue;
        border-radius: 5px;
        -webkit-appearance: none;
        outline: none;
        margin-top: 10px;
        width: 300px;

        >.device {

        }
      }

      >.camera-select {
        width: 60%;
        margin-bottom: 10px;
      }
    }
  }
}

.absolute-left-right-20 {
  left: 20%;
  right: 20%;
}

.absolute-left-right-30 {
  left: 30%;
  right: 30%;
}
