@import '../../../../../../../../../../../../../../style-variables-colors.scss';

.c-PatientDetailItemKey {
  padding: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: flex-start;

  >.icon {
    height: 20px;
    width: 20px;
    margin-right: 10px;
  }

  >.text {
    color: $main-blue;
  }
}
