@import "../../../../../../../../../../../../../../../../style-variables-colors.scss";

.c-Actions {
  >.status {
    font-size: 26px;
  }

  >.status-resolved {
    color: $green;
  }

  >.status-dismissed {
    color: $dark-red;
  }

  >.actions-container {
    display: flex;
    flex: 1 1 auto;

    >.btn {
      height: 40px;
      min-width: 140px;
      max-width: max-content;
      margin: 0 8px;
      font-size: 18px;
      color: $white;
      -webkit-border-radius: 3px;
      -moz-border-radius: 3px;
      border-radius: 3px;
      outline: none;
      border: none;

      &:hover {
        cursor: pointer;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    >.btn-blue {
      background-color: $main-blue;
    }

    >.btn-green {
      background-color: $green;
    }

    >.btn-red {
      background-color: $dark-red;
    }
  }
}
