.c-Kiosk {
  display: flex;
  flex-direction: row;

  >.kiosk-data {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;

    >.healthcare-site-name {
      font-weight: bolder;
      margin-bottom: 8px;
    }

    >.location {

    }
  }

  >.column {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    justify-content: center;

    >.row {
      display: flex;
      flex-direction: row;

      >.progress-bar-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;

        >.progress-bar-label {

        }
      }
    }
  }
}
