.SmartTableHeader {
  display: flex;
  min-width: 100%;
  // padding: 15px;
  padding-left: 15px;
  white-space: nowrap;

  .header-cell {
    display: flex;
    align-items: center;
    // text-align: center;
    box-sizing: border-box;
    padding: 10px 0px;
    // box-shadow: 0 3px 2px -2px rgba(0, 0, 0, 0.1);
    // border-bottom: solid 1px #eee;
    // border-top: solid 1px #f8f8f8;
    // border-left: solid 1px #f2f2f2;
    // border-right: solid 1px #f2f2f2;

    .title {
      color: #999;
      margin-right: 3px;
    }

    .icon {
      width: 10px;
      height: 10px;
      cursor: pointer;
    }
  }
}

