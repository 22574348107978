@import '../../../../../../../../../../../../../../style-variables-colors.scss';


.c-HealthcareSite {
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $light-grey;

  &:hover {
    background: $border-grey;
  }

  &:last-child {
    border-bottom: none;
  }

  >.delete-button {
    cursor: pointer;
    margin-right: 3px;
  }
}
