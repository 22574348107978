$icon-dimensions: 16px;
$side-padding: 12px;

.c-ListSearch {
  // display: flex;
  // flex: 0 0 auto;
  position: relative;
  color: #B6BEC2;

  >.search-icon {
    position: absolute;
    width: $icon-dimensions;
    height: $icon-dimensions;
    left: $side-padding;
    top: 10px;
  }

  >input {
    // flex: 1 0 auto;
    font-size: 15px;
    line-height: 20px;
    padding: 7px $side-padding;
    padding-left: $side-padding + $icon-dimensions + 8px;
    color: #B6BEC2;
    background-color: #F9FAFB;
    border: solid 1px #E2E7E9;
    border-radius: 2px;
    outline: none;
    width: 100%;
  }

  >input::placeholder {
    font-weight: 100;
    font-style: italic;
    color: #B6BEC2;
  }
}
