.c-ListItem {
  .c-KeyValue {
    display: flex;
    flex-direction: row;
    flex: 0 0 auto;
  
    >.label {
      display: flex;
      flex: 0 0 50px;
      margin-right: 10px;
      font-weight: lighter;
    }
  
    >.value {
      display: flex;
      flex: 1 1 auto;
      align-content: flex-start;
    }
  }
}
