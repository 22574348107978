@import '../../../../../style-variables-colors.scss';


.c-PrescriptionFillInfoCards {
  display: flex;
  flex: 1 0 auto;
  height: 300px;
  max-height: 300px;
  flex-direction: column;

  >.row {
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    margin-bottom: 10px;
  }
}
