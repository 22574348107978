@import "../../../style-variables-colors.scss";

.c-CaptionValueInputWithSelect {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  width: 100%;
  margin-right: 10px;
  position: relative;

  >.input {
    height: 30px;
    max-width: 120px;
    padding: 1px 5px;
    font-size: 14px;
    border: 2px solid $light-grey;
    border-radius: 5px;
    outline: none;
  }

  >.items {
    position: absolute;
    top: 28px;
    left: 0;
    right: 0;
    background: $white;
    border: 2px solid $light-grey;
    border-radius: 5px;

    >.item {
      display: flex;
      flex: 0 0 auto;
      justify-content: center;
      background: $white;
      border-bottom: 2px solid $light-grey;
      padding: 3px;
    }

    >.item:last-child {
      border-bottom: none;
    }
  }

  >.max-width {
    max-width: 100%;
  }

  >.caption {
    text-transform: uppercase;
    color: $grey;
  }

  &:last-child {
    margin-right: 0;
  }
}
