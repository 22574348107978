@import "../../../../../../../../style-variables-colors.scss";

.c-KeycardsView {
  background: $white;
  padding: 24px;
  border-left: solid 1px $border-grey;

  .view-title {
    >.title {
      font-size: 24px;
      font-weight: 300;
    }
  }

  .keycards-smart-table {
    .SmartTableHeader {
      font-size: 16px;
    }

    .content-row {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 70px;
      font-size: 16px;
    }
  }
}
