@import '../../../style-variables-colors.scss';


.c-PrescriptionFillSummary {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;

  >.current-status-container {
    display: flex;
    flex-direction: column;
    flex: 0 0 auto;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
    padding: 5px 0;
    height: max-content;
    border: 1px solid $light-grey;
    border-radius: 3px;

    >.title {
      font-size: 20px;
      color: $darker-grey;
      text-transform: uppercase;
    }

    >.status {
      font-size: 16px;
      text-transform: uppercase;
    }
  }
}
