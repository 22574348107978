@import '../../../../../../../../style-variables-colors.scss';


.c-ModulesTemplateView {
  display: flex;
  flex-direction: row;
  height: 100%;

  .add-module-template-button {
    width:max-content;
    height: max-content;
    padding: 5px 10px;
    background: $main-blue;
    color: $spec-white;
    border: none;
  }
}
