@import "../../../../../../../style-variables-colors.scss";

$notifications-pane-width: 55px;

.c-NotificationsPane {
  display: flex;
  flex-direction: column;
  flex: 1 1 $notifications-pane-width;
  max-width: $notifications-pane-width;
  position: relative;
  overflow: visible;
  //overflow-y: auto;
  border-left: 1px solid $light-grey;
}
