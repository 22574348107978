@import '../../../../../../../../../../../../../../style-variables-colors.scss';

.c-KioskListItemStatus {
  display: flex;
  flex-direction: row;

  .status-image {
    height: 25px;
    width: 25px;
  }

  .text-offline {
    color: $pink-red;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-right: 10px;
    text-align: right;
  }

  .status-online, .status-offline {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}
