@import "../../../../../../../../../../../../../../style-variables-colors.scss";


.c-MailOrderedPrescriptionListItem {
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;

  >.prescription-data {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;

    >.flex-row {
      display: flex;
      flex-direction: row;

      >.label {
        margin-right: 10px;
        color: $darker-grey;
        text-transform: uppercase;
      }

      >.value {
        word-wrap: break-all;
      }
    }

    >.kiosk-name-container {
      display: flex;
      justify-content: flex-end;

      >.kiosk-name {
        display: flex;
        font-size: 14px;
        height: 100%;
        align-self: center;
        border: 1.5px solid $main-blue;
        border-radius: 10px;
        padding: 2px 8px;
        margin-top: 20px;

        >.icon-container {
          flex: 0 0 auto;
          margin-right: 6px;
          margin-top: 2px;
        }

        >.name-text {
          flex: 1 1 auto;
        }
      }
    }
  }

}

.active-card {
  border-color: $main-blue !important;
}

.active-name {
  color: $main-blue !important;
  font-size: 18px !important;
  font-weight: 600;
}

.active-time {
  color: $main-blue !important;
}
