.c-StatisticsItem {
  display: flex;
  flex: 1 1 auto;
  width: 100%;
  padding: 10px 0;

  >.caption {
    width: 25%;
  }

  >.progress-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 55%;
  }

  >.numbers {
    width: 20%;
    text-align: center;
  }
}
