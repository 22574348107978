@import "../../../../../../../style-variables-colors.scss";

.c-CallerKioskName {
  width: max-content;
  margin-bottom: 10px;
  padding: 10px 20px;
  font-weight: 600;
  border-radius: 25px;
  background: $white;
  z-index: 1000;
}
