@import '../../../../../../../../../../../../../../style-variables-colors.scss';


.c-StatusDisplay {
  display: flex;
  flex: 1 1 400px;
  flex-direction: column;
  font-size: 22px;

  >.row {
    display: flex;
    flex: 1 1 auto;
    justify-content: flex-end;
    margin-bottom: 10px;

    &:last-of-type {
      margin-bottom: 0;
    }

    >.status-container {
      display: flex;
      flex-direction: column;
  
      >.status {
      }
  
      >.approved {
        color: $light-green;
      }
  
      >.rejected {
        color: $pink-red;
      }
  
      >.consultation-requested {
        color: $main-blue;
      }
  
      >.unidentifiable {
        color: $dark-red;
      }
  
      >.description {
        color: $light-grey;
      }
    }
  
    >.icon-container {
      margin-left: 10px;
      padding-top: 3px;
      max-width: 20px;
  
      >.icon {
        width: 20px;
        height: 20px;
      }
    }
  
    >.button {
      height: 45px;
      width: 120px;
      outline: none;
      border: none;
      margin: 0 5px;
    }
  
    >.approve {
      color: $light-green;
      background: $white;
      border: 2px solid $light-green;
  
      &:hover {
        color: $white;
        background: $light-green;
      }
    }
  
    >.reject {
      color: $pink-red;
      background: $white;
      border: 2px solid $pink-red;
  
      &:hover {
        color: $white;
        background: $pink-red;
      }
    }
  
    >.button:last-child {
      margin-right: 0;
    }
  
    >.source {
      width: 100%;
      text-align: right;
      color: $grey;
      font-size: 17px;
    }
  }
}
