@import '../../../../../../../../../style-variables-colors.scss';


.c-EmployeeInfo {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  //height: 30%;
  padding: 0 15px;

  >.header {
    display: flex;
    flex: 0 1 auto;
    flex-direction: row;
    margin-top: 30px;
    margin-bottom: 10px;
    justify-content: flex-start;
    align-items: center;

    >.icon {
      width: 20px;
      height: 20px;
      margin-right: 8px;
    }

    >.title {
      color: $main-blue;
      font-size: 20px;
      font-weight: 300;
    }
  }

  >.content {
    display: flex;
    flex-direction: row;
    flex: 1 1 auto;
    justify-content: space-between;

    >.caller, >.location {
      display: flex;
      flex: 1 1 auto;
      flex-direction: column;
      width: 50%;

      >.key-value-container {
        display: flex;
        flex-direction: column;
        flex: 0 1 auto;

        >.key {
          text-transform: uppercase;
          color: $darker-grey;
          font-weight: 600;
          margin-bottom: 5px;
        }

        >.value {
          font-weight: 500;
        }
      }
    }
  }
}
