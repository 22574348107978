@import "../../../../../../../../../style-variables-colors.scss";

.c-AwayModeModal {
  position: absolute;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 20px;
  background-image: linear-gradient(130deg, $pale-blue 10%, $main-blue 90%);
  outline: none;

  >.content {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    width: 100%;
    color: $white;

    >.title-container {
      width: 100%;
      text-align: center;
      margin-bottom: 100px;

      >.title {
        font-size: 100px;
        font-weight: 100;
      }
  
      >.subtitle {
        font-size: 45px;

        >.bold {
          font-weight: 600;
        }
      }
    }

    >.exit-away-mode {
      font-size: 40px;
      cursor: pointer;
    }
  }
}
