@import '../../../../../../../../../../../../../../../style-variables-colors.scss';


.c-PatientDataCapturedManipulation {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  //justify-content: flex-end;
  min-height: 40px;
  //max-height: 50px;

  >.data-capture-buttons {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    margin-bottom: 10px;

    &:last-of-type {
      margin-bottom: 0;
    }

    >.button {
      height: 40px;
      width: 185px;
      margin: 0 8px 10px 8px;
      font-size: 17px;
      -webkit-border-radius: 3px;
      -moz-border-radius: 3px;
      border-radius: 3px;
      outline: none;
      border: none;

      &:hover {
        cursor: pointer;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    >.blue {
      color: $white;
      background: $main-blue;
      border: 2px solid $main-blue;
    }

    >.disabled {
      border: 1px solid $light-grey;
      color: 1px solid $light-grey;
    }

    >.data-capture-requested {
      font-size: 18px;
      color: $main-blue;
      text-align: center;
      margin-top: 10px;
    }
  }
}
