@import "../../../../../../../../../../style-variables-colors.scss";


.c-KioskStatuses {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  padding: 10px;
  overflow: hidden;

  >.content {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;

    >.section {
      display: flex;
      flex-direction: column;
      flex: 0 1 auto;
      margin-bottom: 30px;
  
      &:last-of-type {
        margin-bottom: 0;
      }
  
      >.row {
        display: flex;
        flex: 1 1 auto;
      }
    }
  }
}
