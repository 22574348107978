@import '../../../../style-variables-colors.scss';

.c-EntityHealthcareSite {
  display: flex;
  flex-direction: column;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid $light-grey;

  &:last-of-type {
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: none;
  }

  >.healthcareSite-info {
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    &:last-of-type {
      margin-bottom: 0;
    }

    > .icon {
      height: 20px;
      width: 20px;
      margin-right: 10px;
    }

    > .value {

    }
  }
}
