@import "../../../../../style-variables-colors.scss";

$video-square-size: 500px;

.c-VideoConferenceModal {
  border-radius: 5px;
  background-color: $white;
  outline: none;

  video {
    object-fit: cover;
  }

  >.video-conference-wrapper {
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    overflow-y: hidden;
    overflow-x: hidden;

    >.video-conference-container {
      display: flex;
      flex: 1 1 $video-square-size;
      min-height: $video-square-size;

      >.video-conference {
        display: flex;
        flex: 0 0 auto;
        flex-direction: column;
        position: relative;
        z-index: 10;
        align-items: center;
        height: $video-square-size;
        width: $video-square-size;

        >.connection-status {
          display: flex;
          flex: 1 1 auto;
          justify-content: center;
          align-items: center;
          font-size: 32px;
          font-weight: 500;
          color: #B6BEC2;
        }

        >.temporary-title {
          position: absolute;
          top: 100px;
          left: 0;
          right: 0;
          margin: auto;
          text-align: center;
          font-size: 38px;
          color: $white;
          z-index: 14;
        }

        >.local-media {
          display: flex;
          position: absolute;
          top: 20px;
          left: 20px;

          width: 20%;
          height: 20%;
          z-index: 13;
        }

        >.remote-media {
          display: flex;
          flex-direction: column;
          flex: 1 1 auto;
          position: relative;
          height: 100%;
          width: 100%;
          padding-top: 10px;
          padding-left: 10px;

          //>.caller-kiosk-name {
          //  position: absolute;
          //  top: 10px;
          //  left: 10px;
          //  padding: 10px 20px;
          //  font-weight: 600;
          //  border-radius: 25px;
          //  background: $white;
          //  z-index: 1000;
          //}

          >video {
            width: 100%;
            // height: 100%;
            border-radius: 5px;
            margin-bottom: 10px;
            z-index: 12;
            object-fit: cover;

            &:first-of-type {
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 100%;
              margin: 0;
              border-radius: 0 0 5px 5px;
              transform: rotate(180deg);
            }

            &:last-of-type {
              margin-bottom: 0;
            }
          }
        }

        >.end-call-button-container, >.end-call-button-container[disabled] {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100px;
          height: 35px;
          position: absolute;
          bottom: 50px;
          z-index: 13;

          font-size: 18px;
          background: $dark-red;
          border: none;
          outline: none;
          cursor: pointer;

          >.phone-icon {
            margin-right: 15px;
            width: 30px;
            height: 12px;
          }

          >.text {
            color: $white;
          }
        }

        >.end-call-button-container[disabled] {
          color: $white;
          background: $light-grey;
          cursor: auto;

          &:hover::after {
            display: inline-block;
            position: absolute;
            left: 120px;
            bottom: 0;
            content: 'Please, review prescriptions below'
          }
        }
      }

      >.call-ended-container {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        justify-content: center;
        align-items: center;

        >.call-ended-text {
          margin-bottom: 72px;
        }
      }
    }

    >.call-info {
      display: flex;
      flex: 1 1 $video-square-size;
      width: $video-square-size;
      height: 100%;
      overflow-y: auto;
    }

    >.h50 {
      height: 50%;
    }

    >.h80 {
      height: 80%;
    }
  }
}

.absolute-left-right-20 {
  left: 20%;
  right: 20%;
}

.absolute-left-right-30 {
  left: 30%;
  right: 30%;
}
