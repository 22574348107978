@import "../../../../../../../../../../../../../../style-variables-colors.scss";

.c-SimplifiedInventory, #simplified-inventory-report {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  margin-bottom: 20px;
  padding: 10px;
  font-family: Helvetica, sans-serif;

  >.title-container {
    display: flex;
    flex: 0 1 auto;
    margin-bottom: 30px;

    >.title {
      flex: 1 1 auto;
      text-align: center;
      font-size: 11pt;
    }
  }

  >.inventory-container {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    width: 100%;
    overflow-y: auto;

    >.drug-container {
      display: flex;
      flex: 0 1 auto;
      flex-direction: column;

      >.row-single-quantity, >.row-multiple-quantities {
        display: flex;
        flex: 0 1 auto;
        width: 100%;
        margin-bottom: 25px;
        font-size: 10pt;
  
        >.name {
          width: 45%;
        }
    
        >.NDC {
          width: 30%;
        }
    
        >.quantity {
          width: 25%;
        }
      }
  
      >.row-multiple-quantities {
        margin-bottom: 10px;
  
        &:last-of-type {
          margin-bottom: 25px;
        }
      }
    }
  }
}
