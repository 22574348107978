@import "../../../../../../../../../../style-variables-colors.scss";

.c-ExpandedPrescriptionInfoModal {
  border-radius: 5px;
  background-color: rgba(240, 240, 240, 0.9) !important;
  outline: none;

  flex: 1 1 auto;
  flex-direction: row;
  padding: 20px;

  >.close-modal-icon {
    position: absolute;
    right: 20px;
    top: 20px;
    height: 26px;
    cursor: pointer;
  }

  >.left-side {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    width: 50%;
  }

  >.right-side {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    width: 50%;

    >.top-block, >.bottom-block {
      display: flex;
      flex: 0 0 auto;
      flex-direction: column;
      height: 30%;
    }

    >.bottom-block {
      height: 70%;
      overflow-y: auto;
    }
  }
}
