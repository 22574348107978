@import '../../../../style-variables-colors.scss';


.c-appCommandFilter {
  display: flex;
  flex-direction: row;
  flex: 0 0 auto;
  border-bottom: 1px solid $border-grey;
  padding: 5px;
  justify-content: flex-end;
  align-items: center;

  >.filter-image {
    height: 15px;
    width: 15px;
    margin-right: 10px;
  }

  >.filter-select {
    outline: none;
    width: 300px;
  }
}
