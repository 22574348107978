@import "../../../../../../../../style-variables-colors.scss";

.allergies {
  display: flex;
  flex: 0 1 auto;
  align-items: center;

  >.allergy {
    margin-right: 10px;
    background: $dark-red;
    color: $white;
    border-radius: 3px;
    padding: 3px 10px;
  }

  >.remaining-allergies-count {
    color: $dark-red;
    text-transform: uppercase;
  }
}
