@import "../../../../../../../../../../style-variables-colors.scss";

.c-PatientUpsertModalAllergiesContainer {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: 50%;
  overflow-y: auto;

  >.title {
    text-transform: uppercase;
    color: $grey;
    margin-bottom: 20px;
  }

  >.allergies {
    display: flex;
    flex: 0 1 auto;
    width: 100%;
    overflow-y: auto;

    >.selected {
      width: 100%;
      display: flex;
      flex-wrap: wrap;

      >.allergy {
        background: $dark-red;
        border-radius: 3px;
        color: $white;
        padding: 5px 10px;
        margin-right: 10px;
        margin-bottom: 10px;
        height: 35px;

        >.allergy-remove-icon {
          margin-left: 15px;
          color: $white;
          cursor: pointer;
        }
      }

      >.allergy-input-container {
        height: 35px;

        >.allergy-input {
          width: 200px;
          height: 100%;
          padding: 0 10px;
          font-size: 16px;
          border-radius: 5px;
          border: 1px solid $light-grey;
        }
      }
    }
  }
}
