@import '../../../../../../../../../../../../../../style-variables-colors.scss';


.c-DispenseAttemptStatusManipulation {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  //justify-content: flex-end;
  min-height: 40px;
  //max-height: 50px;

  >.row {
    display: flex;
    flex: 1 1 auto;
    justify-content: space-between;
    margin-bottom: 10px;

    &:last-of-type {
      margin-bottom: 0;
    }

    >.button {
      height: 40px;
      min-width: 140px;
      max-width: max-content;
      margin: 0 8px;
      font-size: 18px;
      -webkit-border-radius: 3px;
      -moz-border-radius: 3px;
      border-radius: 3px;
      outline: none;
      border: none;

      &:hover {
        cursor: pointer;
      }

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    >.approve {
      color: $white;
      background: $light-green;
      border: 2px solid $light-green;
    }

    >.reject {
      color: $white;
      background: $dark-red;
      border: 2px solid $dark-red;
    }

    >.consultation-request {
      width: 100%;
      max-width: 100%;
      color: $white;
      background: $main-blue;
      border: 2px solid $main-blue;
    }

    >.disabled {
      border: 1px solid $light-grey;
      color: 1px solid $light-grey;
    }

    >.source {
      width: 100%;
      text-align: right;
      color: $grey;
      font-size: 17px;
    }
  }

  >.consultation-took-place {
    font-size: 18px;
    color: $main-blue;
    text-align: center;
  }
}
