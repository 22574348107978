@import "../../../../../../../../../../../style-variables-colors.scss";


.c-MedicalAssistantsListMA {
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;

  >.facility {
    color: $text-light-grey;
  }
}
