@import "../../../../../../../../../style-variables-colors.scss";

$active-link-vertical-line-width: 3px;
$link-margin-left: 12px;

.c-NavbarLinks {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  overflow-y: auto;

  >.link-container {
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    align-items: flex-start;
  }
}
