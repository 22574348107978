@import "../../../../../style-variables-colors.scss";


.c-ChangePasswordView {
  display: flex;
  flex: 1 1 100%;
  justify-content: center;
  align-items: center;
  position: relative;

  .primary-panel {
    flex: 0 0 auto;
    display: flex;
    background-color: $white;
    box-shadow: 0 20px 28px 2px rgba(0, 0, 0, 0.05);
    border-radius: 20px;

    .sub-panel {
      display: flex;
      flex-direction: column;
      padding: 75px 100px;

      .back-to-sign-in {
        margin-top: -65px;
        margin-left: -80px;
        position: absolute;

        a {
          color: $white;
        }

        &:hover {
          text-decoration: underline;
        }
      }

      &.left-panel {
        justify-content: center;

        .logo-icon {
          margin-bottom: 30px;
        }

        .kiosk-image {
        }
      }

      &.right-panel {
        background-color: $main-blue;
        color: $white;
        border-radius: 20px;

        .greeting-text {
          flex: 0 0 auto;
          h3 {
            width: 100%;
            text-align: center;
            font-size: 24px;
            font-weight: 300;
            margin-bottom: 41px;
          }
        }
      }
    }

    .input-fields {
      display: flex;
      flex: 0 0 auto;
      flex-direction: column;
      min-width: 304px;
      margin-bottom: 15px;

      .input-field {
        margin-bottom: 10px;

        .input-label {
          font-size: 14px;
          margin-bottom: 5px;
        }

        input {
          width: 100%;
          height: 36px;
          padding: 10px 8px;
          border: none;
          border-radius: 2px;
          box-shadow: inset 0 1px 5px 1px $input-shadow-from,
                      inset 0 1px 2px 0px $input-shadow-to;
        }
      }
    }

    .panel-footer {
      display: flex;
      justify-content: center;
      flex-direction: column;

      .sign-up-button {
        width: 100%;
        height: 36px;
        font-size: 15px;
        padding: 5px 10px;
        border: solid 2px $white;
        border-radius: 2px;
        cursor: pointer;
        color: $white;
        background-color: transparent;
      }

      .error-message {
        font-size: 15px;
        text-align: center;
        color: $gold;
        width: 100%;
        padding-top: 10px;
      }
    }
  }
}
