@import '../../../../../../../../../../style-variables-colors.scss';


.r-PrescriptionFillsMailOrderedView {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
}
