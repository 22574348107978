.c-ReviewConflictModal {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;

  >.conflict-message {
    font-size: 22px;
    text-align: center;
  }
}
