@import "../style-variables-colors.scss";

body, textarea {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  font-family: 'Titillium Web', sans-serif;
}

h1, h2, h3, h4, h5 {
  margin: 0;
}

div {
  box-sizing: border-box;
}

button {
  &.btn {
    padding: 8px 18px;
    font-size: 18px;
    border-radius: 3px;
    outline: none;
    border: none;
    color: #FFFFFF;
    cursor: pointer;

    &[disabled] {
      background-color: #ccc !important;
    }
    
    &.red {
      background-color: $dark-red;
    }

    &.blue {
      background-color: $main-blue;
    }
  }
}
