@import "../../../../../../../../../../../../../style-variables-colors.scss";


.c-ErroredKioskNotification {
  display: flex;
  flex: 0 0 auto;
  width: 100%;
  flex-direction: column;

  >.title {
    font-size: 17px;
    margin-bottom: 5px;
    color: $darker-grey;
  }

  >ul {
    font-size: 15px;
    padding-left: 15px;

    >li {
      white-space: normal;
    }
  }
}
