.c-SendReceiptView {
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  align-items: center;
  position: relative;

  .receipt-checkbox {
    margin-right: 25px;

    &:last-of-type {
      margin-right: 0;
    }
  }

  .form-area-container {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    justify-content: center;
    align-items: center;

    .form-area {
      display: flex;
      flex-direction: column;
      flex: 0 0 auto;
      width: 350px;
      gap: 24px;
      min-height: 200px;
      justify-content: flex-start;

      input {
        padding: 12px 8px;
        height: auto;
        font-size: 18px;
      }

      .email-match-text {
        font-size: 18px;
      }
    }
  }

  .actions-area {
    display: flex;
    flex: 0 0 auto;
    // align self to flex end
    align-self: flex-end;
    width: 100%;
    justify-content: space-between;
  }
}
