@import '../../../../../../../../../../style-variables-colors.scss';

.c-ModuleTemplate {
  display: flex;
  flex-direction: row;
  height: max-content;
  width: 100%;
  justify-content: space-between;

  >.module-template-data {
    display: flex;
    flex-direction: column;
    padding-left: 13px;
    >.module-size-parameters {
      display: flex;
      flex-direction: row;
      width: 100%;
      >.modules-size {
        display: flex;
        flex-direction: column;
        margin-left: 5px;
        >.param {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          >.key {
            color: $grey;
            margin-right: 5px;
          }
          >.value {

          }
        }
      }
    }
  }

  >.module-template-silhouette {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-left: auto;

    >.module-template-remove-button {
      display: flex;
      align-self: flex-end;
      margin-bottom: 5px;
      width: max-content;
      height: max-content;
      background: $spec-white;
      color: $black;
      outline: none;
      border: none;
      cursor: pointer;
    }

    >.silhouette {
      display: flex;
      height: 100%;
      align-items: flex-end;
    }

    >.module-template-remove-button {
      display: flex;
      align-self: flex-end;
      margin-bottom: 5px;
      width: max-content;
      height: max-content;
      background: $spec-white;
      color: $black;
      outline: none;
      border: none;
      cursor: pointer;
    }
  }
}