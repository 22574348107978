.c-StockingBatchDrugs {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  padding: 10px;

  >.filter {
    margin-bottom: 10px;
  }

  >.StockingBatchDrugsTable {
    .header-cell {
      margin-right: 4px;
    }
  
    .drug-name {
      padding-right: 10px;
    }
  
    .NDC, .quantity {
      font-weight: 600;
      margin-right: 2px;
    }
  
    .quantity {
      margin-left: 8px;
    }
  
    .numbered-item {
      text-align: right;
      padding-right: 10px;
    }
  }

  >.no-drugs-text {
    font-size: 16px;
  }
}
