@import '../../../../../../../../style-variables-colors.scss';


.r-HealthcareSitesView {
  display: flex;
  flex: 1 1 0px;
  overflow: hidden;

  .title-icon {
    img {
      position: absolute;
      width: 80%;
      left: 10%;
    }
  }

}
