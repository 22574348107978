@import "../../../../../../../../../../style-variables-colors.scss";

.c-PrescriptionFillsStats {
  display: flex;
  flex-direction: column;

  >.header-area {
    display: flex;
    flex: 0 0 auto;
    margin-bottom: 28px;

    >.title-area {
      flex: 1 1 auto;
    }

    >.filters-area {
      flex: 0 0 auto;
      display: flex;
      align-items: flex-start;

      >.invert-btn-container {
        display: flex;
        flex: 1 1 auto;
        margin-left: 12px;

        >.invert-btn {
          align-self: center;
          font-size: 18px;
          width: 140px;
          height: 35px;
          color: $white;
          background-color: $main-blue;
          outline: none;
          border: none;
          border-radius: 3px;
          cursor: pointer;
        }
      }
    }
  }

  >.c-Content {
    padding: 0;

    .tabs-container {
      margin-top: 0;
    }
  }

  .red { 
    color: $dark-red;
  }
  
  .green {
    color: $green;
  }
}
