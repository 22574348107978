.c-ListItemGroup {
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;

  >.title-container {
    display: flex;
    flex: 0 0 auto;
    margin-bottom: 24px;

    >.title {
      flex: 0 0 auto;
      text-transform: uppercase;
      color: #B6BEC2;
      font-size: 15px;
      line-height: 20px;
      margin-right: 24px;
    }

    >.horizontal-rule-container {
      flex: 1 0 auto;

      >hr {
        display: block;
        height: 1px;
        border: 0;
        border-top: 1px solid #E2E7E9;
        padding: 0; 
        margin-top: 10px;
      }
    }
  }
}
