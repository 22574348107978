@import "../../../../../../../../../style-variables-colors.scss";
@import "../../header-view.scss";

.dropdown {
  display: flex;
  position: relative;
  align-items: center;
  font-size: 18px;
  margin: 0px auto 0px 25px;
  -webkit-padding-start: 0px;
  -webkit-margin-before: 0px;
  -webkit-margin-after: 0px;
  z-index: 1101;

  .menu-icon {
    height: 25px;
    width: 25px;
    cursor: pointer;
  }

  .overlay {
    background-color: rgba(0, 0, 0, 0.39);
    position: fixed;
    top: $header-height;
    right: -20px;
    left: 0;
    bottom: 0;
  }

  ul {
    position: absolute;
    top: $header-height;
    right: -25px;
    left: auto;
    margin: 0;
    padding: 0;
    list-style-type: none;
    z-index: 1102 ;

    li:last-child {
      border-bottom: none;
    }
  }

  li, li a {
    display: inline-block;
    height: 50px;
    line-height: 50px;
    text-align: center;
    background: $white;
    min-width:140px;
    width: 250px;
    border-bottom: 2px solid $light-grey;
    cursor: pointer;
  }

  li:hover, li:hover a {
    background: $main-blue;
    color: $white;
  }
}
