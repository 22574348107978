@import "../../../../../../../../../../../../../style-variables-colors.scss";
@import "./animations.scss";


.c-ActiveVideoConferenceNotification {
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  width: 100%;
  cursor: pointer;

  >.active-sign {
    height: 20px;
    width: 20px;
    background-color: $dark-red;
    border-radius: 50%;
    display: inline-block;
    @include pulse-animation(2s, $dark-red, 8px);
  }
}
