@import '../../../../../../../../../../../../style-variables-colors.scss';


.c-KeyValuePair {
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  width: 170px;
  max-width: 170px;
  word-break: break-word;
  padding-bottom: 10px;

  >.title {
    color: $main-blue;
    display: flex;
    flex: 0 0 auto;
    font-size: 20px;
  }

  >.value {
    display: flex;
    flex: 0 0 auto;

    >.complex-value {
      display: flex;
      flex: 0 0 auto;
      flex-direction: column;

      >.value {

      }
    }
  }

  &:last-child {
    border-right: none;
  }
}
