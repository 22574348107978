@import '../../../style-variables-colors.scss';

.c-AppCommandsTable {
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  overflow: hidden;
  position: relative;

  >.horizontal-line {
    border: solid 1px $light-grey;
    height: 0;
  }

  >.filter-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: flex-end;
    padding: 5px 0;
    flex-shrink: 0;
    flex-basis: auto;

    >.filter-image {
      height: 15px;
      width: 15px;
      margin-right: 10px;
    }

    >.filter-select {
      outline: none;
      width: 300px;
    }
  }

  .app-commands-by-date {
    display: flex;
    flex-direction: column;
    padding: 28px 24px;
    flex: 1 1 auto;

    >.by-particular-date {
      display: flex;
      flex: 1 1 auto;
      flex-direction: column;

      >.date-container {
        display: flex;
        flex-direction: row;
        white-space: nowrap;
        padding-bottom: 15px;
        flex: 0 0 auto;

        >.date {
          color: $main-blue;
          font-weight: 600;
          padding-right: 10px;
        }

        >.separator {
          margin: auto 0;
          width: 100%;
          border: 1px solid $main-blue;
          border-left: none;
        }
      }
    }

    >.no-data {
      text-align: center;
      font-size: 28px;
      color: $darker-grey;
      margin: auto;
    }
  }
}
