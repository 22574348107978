@import '../../../../../../../../../../../../style-variables-colors.scss';

.c-PrescriptionsHistory {
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  overflow: hidden;

  >.content {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    overflow-y: auto;
    padding: 5px 20px;

    >.c-content-gate {
      >.prescription-container {
        display: flex;
        flex-direction: column;
        flex: 0 1 auto;
        padding-bottom: 15px;
        margin-bottom: 15px;
        border-bottom: 1px solid $border-light;
  
        >.title {
          font-size: 20px;
          margin-bottom: 10px;
        }
    
        >.row {
          display: flex;
          flex: 1 1 auto;

          >.column {
            display: flex;
            flex-direction: column;
            flex: 1 1 auto;
      
            >.title-container {
              display: flex;
              flex: 0 1 auto;
              text-align: left;
              margin-bottom: 10px;
              font-size: 18px;
              color: $main-blue;
      
              >.icon {
                margin-right: 10px;
                width: 20px;
                height: 20px;
                align-self: center;
              }
      
              >.title {
                height: 100%;
                text-align: left;
              }
            }
      
            >.key-value-container {
              margin-bottom: 10px;
    
              &:last-of-type {
                margin-bottom: 0;
              }
            
              .key {
                font-weight: lighter;
              }
            
              .value {
                font-weight: 600;
                word-wrap: break-word;
              }
            }
          }
        }

      }
    }
  }
}
