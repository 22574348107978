@import "../../../style-variables-colors.scss";


html {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  color: $dark-grey;

  a {
    text-decoration: none;
    color: $dark-grey;
  }
}

* {
  box-sizing: border-box;
}

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-color: $spec-white;
}

#root {
  display: flex;
  flex: 1 1 100%;
  height: 100%;
  overflow: hidden;
}

.scroll-container {
  overflow-y: auto;
}

.c-RootView {
  display: flex;
  flex: 1 1 100%;
}