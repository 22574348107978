.c-List {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: hidden;
  border-left: solid 1px #E2E7E9;
  border-right: solid 1px #E2E7E9;

  >.list-body {
    display: flex;
    flex-direction: column;
    flex: 1 1 0;
    padding: 24px;
    overflow-y: auto;
    position: relative;

    >.c-content-screen {
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
    }

    .c-ListItem {
      margin-bottom: 24px;
    }
  }
}
