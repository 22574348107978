@import "../../../../../../../../../../style-variables-colors.scss";


.c-KioskInventory {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  padding: 10px;
  overflow: hidden;
}
