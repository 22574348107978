@import '../../../style-variables-colors.scss';


.c-Paginator {
  width: max-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;

  >.title {
    font-weight: 500;
    color: $grey;
    margin-right: 15px;
    text-transform: uppercase;
  }

  >.arrow {
    color: $darker-grey;
    cursor: pointer;
  }

  >.arrow-back {
    margin-right: 10px;
  }

  >.arrow-forward {
    margin-left: 10px;
  }

  >.disabled {
    pointer-events: none;
  }

  >.pagination-numbers {
    display: flex;
    flex-direction: row;

    >.number, >.number-active {
      border: 1px solid $darker-grey;
      padding: 0 6px;
      vertical-align: center;
      color: $darker-grey;
      margin-right: 5px;
      border-radius: 3px;
      font-size: 13px;
      cursor: pointer;

      &:last-child {
        margin-right: 0;
      }
    }

    >.number-active {
      background: $light-grey;
    }

    >.dots {
      margin-right: 5px;
    }
  }
}