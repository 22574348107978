@import "../../../../../../../style-variables-colors.scss";

.c-EntriesCount {
  display: flex;
  flex: 0 1 auto;
  justify-content: space-between;
  align-items: center;
  width: max-content;
  margin-right: 50px;

  >.count, >.caption {
    font-size: 16px;
    text-transform: uppercase;
    color: $grey;
  }

  >.count {
    margin-right: 4px;
  }
}
