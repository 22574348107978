@import '../../../../../style-variables-colors.scss';

.c-AppCommand {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex: 0 0 auto;

  >.main-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-bottom: 1px solid $light-grey;
    margin-bottom: 15px;

    >.main-items {
      display: flex;
      flex-direction: row;
      padding-bottom: 15px;
      justify-content: space-between;

      >.item {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        text-align: right;
        flex: 1 1 0px;
        margin-left: 10px;

        >.value {
          word-break: break-word;

          >.success-resolution {
            color: $green;
          }
          >.error-resolution {
            color: $pink-red;
          }
        }

        >.key {
          font-size: 12px;
          color: $darker-grey;
          white-space: nowrap;
          text-transform: uppercase;
        }
      }

      >.type {
        flex: 1 1 60px;
      }
    }
  }

  >.created-at {
    white-space: nowrap;
    font-weight: lighter;
    margin-right: 10px;
    color: #B6BEC2;
    font-size: 15px;
    line-height: 20px;
  }

  &:last-of-type {
    >.main-content {
      border-bottom: none;

      >.main-items {
        padding-bottom: 0;
      }
    }
  }
}
