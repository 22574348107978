@import "../../../style-variables-colors.scss";

.c-DatePeriodPicker {
  display: flex;
  flex: 0 0 auto;

  >.date-range-picker {
    margin-left: 12px;

    >.start-date {
      margin-right: 12px;
    }

    >.react-date-picker {
      >.react-date-picker__wrapper {
        border-radius: 4px;
        padding: 3px;
      }
    }

    .react-date-picker__calendar--open{
      inset: 100% 0px auto auto !important;
    }
  }
}
