@import '../../../../../../../../../../style-variables-colors.scss';

.c-ConsultationRequestModal {
  background: $white;
  outline: none;
  font-size: 20px;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  background: $white;

  flex-direction: column;
  flex: 1 1 auto;
  padding: 20px;

  >.reason-select {
    padding-bottom: 10px;

    >.Select-control {
      cursor: pointer;
    }
  }

  >.text-input {
    font-size: 20px;
    height: 80%;
    resize: none;
    outline: none;
    border: 1px solid $light-grey;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    padding: 10px;
    margin-bottom: 20px;
  }
}
