.c-ListHeader {
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  padding: 24px;
  border-bottom: solid 1px #E2E7E9;
  background-color: #FFFFFF;

  >.title-container {
    flex: 0 0 auto;

    >.title {
      font-size: 24px;
      color: #59A3CF;
      font-weight: 300;
    }
  }

  >.search-container {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    margin-top: 28px;
  }
}
