@import "../../../../../../../../../../../../../../style-variables-colors.scss";


.c-PatientListItemDetails {
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;

  >.key-value {
    display: flex;
    flex-direction: row;

    >.label {
      flex: 0 0 80px;
      margin-right: 10px;
    }

    >.value {
      flex: 1 1 auto;
      font-weight: 600;
    }
  }

  >.detail {
    color: $text-light-grey;
  }
}
