@import '../../../../../../../../../../../../../../style-variables-colors.scss';


.patient-icon-preview-large {
  border-radius: 50%;
  background: $light-grey;
  width: 65px;
  height: 65px;
  margin-right: 10px;
}
