.SmartTable {
  overflow-y: hidden;
  overflow-x: auto;
  white-space: nowrap;
  display: flex;
  font-size: 14px;

  .smart-table-scroll-x {
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  .smart-table-content {
    display: flex;
    flex: 1;
    flex-direction: column;
    position: relative;

    >.c-content-screen {
      display: flex;
      flex: 1 1 auto;
    }
  }
}

.content-row {
   border: solid 1px #EBEBEB;
   margin-left: 0px !important;
   margin-right: 0px !important;
   background-color: white;
  padding-left: 15px;
 }
