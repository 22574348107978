@import '../../../../../../../../../../style-variables-colors.scss';

.c-MedicalAssistantContact {
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  overflow: hidden;

  >.data {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    overflow-y: auto;
    padding: 5px 20px;

    >.data-row {
      display: flex;
      flex: 0 1 auto;
      justify-content: space-between;
      border-bottom: 1px solid $border-grey;

      >.name, >.name-row {
        padding: 15px;
        display: flex;
        flex-direction: row;
        align-items: center;
        align-self: flex-start;

        >.icon {
          height: 20px;
          width: 20px;
          margin-right: 10px;
        }

        >.text {
          color: $main-blue;
        }
      }

      >.name-row {
        display: flex;
        flex-direction: row;
        flex: 1 0 auto;
      }

      >.value {
        display: flex;
        align-items: center;
      }
    }
  }
}
