@import "../../../../../../../../../../../style-variables-colors.scss";
@import "../../links-styles.scss";

.c-NavbarRoute {
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 12px 12px 12px $link-margin-left;
  cursor: pointer;

  >.route {
    display: flex;
    flex: 0 0 auto;
    align-items: center;

    >.icon {
      width: 18px;
      height: 18px;
      margin-right: 10px;

      >img {
        width: 18px;
        height: 18px;
      }
    }

    >.name {
      font-size: 16px;
      color: $black;
    }

    >.name-active {
      color: $main-blue;
    }
  }

  >.sub-routes-dropdown {
    >.icon {
      >img {
        width: 18px;
        height: 12px;
      }
    }
  }
}

.c-NavbarRouteActive {
  border-left: 3px solid $main-blue;
  padding-left: $link-margin-left - $active-link-vertical-line-width;
}
