@import "../../../../../style-variables-colors.scss";

.c-ModalButton {
  border: none;
  outline: none;
  width: 150px;
  height: 50px;
  font-size: 20px;
  color: $white;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 20px;

  &:last-of-type {
    margin-right: 0;
  }

  &--blue {
    background: $main-blue;
  }

  &--red {
    background: $dark-red;
  }
}

.c-ModalButton[disabled] {
  pointer-events: none;
  background: $light-grey;
}
