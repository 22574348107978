@import "../../../../../../../../../../style-variables-colors.scss";


.c-KioskContainersView {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  padding: 10px;
  overflow: hidden;

  >.header {
    display: flex;
    flex: 1 1 auto;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;

    >.search-container {
      display: flex;
      flex: 1 1 auto;
      width: 100%;
      margin-right: 20px;

      >.c-ListSearch {
        width: 100%;
      }
    }

    >.buttons-container {
      display: flex;
      flex: 1 1 auto;
      justify-content: flex-end;

      >.button {
        height: 40px;
        min-width: 140px;
        max-width: max-content;
        margin: 0 8px;
        font-size: 18px;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;
        outline: none;
        border: none;
        color: $white;
        background-color: $main-blue;
  
        &:hover {
          cursor: pointer;
        }
  
        &:last-child {
          margin-right: 0;
        }
      }
  
      >.button-disabled {
        background-color: $light-grey;   
        
        &:hover {
          cursor: default;
        }
      }
    }
  }

  >.content {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;

    >.section {
      display: flex;
      flex-direction: column;
      flex: 0 1 auto;
      margin-bottom: 30px;
  
      &:last-of-type {
        margin-bottom: 0;
      }
  
      >.row {
        display: flex;
        flex: 1 1 auto;
      }
    }

    .drug-name {
      padding-right: 10px;
    }
  
    .NDC, .quantity {
      font-weight: 600;
      margin-right: 2px;
    }

    .quantity {
      margin-left: 8px;
    }
  
    .numbered-item {
      text-align: right;
      padding-right: 10px;
    }
  }
}
