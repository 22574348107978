@import '../../../../../../../../../../style-variables-colors.scss';

.c-PatientsInfo {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;

  >.edit-controls-container {
    display: flex;
    flex: 0 1 auto;
    justify-content: flex-end;
    margin-top: 15px;
    margin-bottom: 20px;

    >.btn-edit-control {
      width: 100px;
      height: 40px;
      color: $white;
      font-size: 16px;
      border: none;
      border-radius: 3px;
      cursor: pointer;
    }

    >.btn-blue {
      background-color: $main-blue;

      &:disabled {
        background-color: $light-grey;
      }
    }
  }
}
