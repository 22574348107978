@import "../../../../../../../../../../../../style-variables-colors.scss";


.c-HealthcareSiteHeaderTitle {
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  justify-content: space-between;

  >.title {

  }

  >.add-new-button {
    font-size: 15px;
    border: none;
    background: $main-blue;
    color: $white;
    padding: 3px;
    border-radius: 3px;
    outline: none;
  }
}
