$icon-dimensions: 16px;
$side-padding: 12px;

.c-AutocompleteView {
  display: flex;
  flex-direction: column;

  .search-area {
    display: flex;
    flex-direction: column;

    .input-container {
      position: relative;

      >.search-icon {
        position: absolute;
        width: $icon-dimensions;
        height: $icon-dimensions;
        left: $side-padding;
        top: 10px;
      }

      >.search-input {
        padding: 8px 10px;
        padding-left: 34px;
        border: 2px solid #ccc;
        border-radius: 4px;
        font-size: 14px;
        outline: none;
        flex: 0 0 auto;
      }
    }

    .suggestions-wrapper {
      position: relative;
      display: flex;
      flex-direction: column;
      margin-top: 2px;

      .suggestions-container {
        background-color: #FFF;
        position: fixed;
        display: flex;
        flex-direction: column;
        border: 2px solid #CCC;

        .suggestion-container {
          padding: 4px 8px;
          border-bottom: 1px solid #CCC;
          cursor: pointer;
        }
      }
    }
  }
}
