.c-ContentHeader {
  >.header-container {
    display: flex;
    justify-content: space-between;
    flex: 1 0 auto;
    margin-bottom: 20px;

    >.title-container {
      display: flex;
      flex-direction: column;
      width: 100%;

      >.title {
        font-size: 36px;
        line-height: 40px;
        color: #59A3CF;
        font-weight: 100;
        word-break: break-all;
      }

      >.subtitle {
        margin-top: 8px;
        font-size: 20px;
        line-height: 24px;
        color: #B6BEC2;
        font-weight: 300;
      }
    }
  }
}
