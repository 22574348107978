@import '../../../../../../../../style-variables-colors.scss';

$header-height: 60px;

.c-PrescriptionFillUpsert {
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  overflow-y: hidden;

  >.header {
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    height: $header-height;
    justify-content: space-between;
    padding: 10px;
    align-items: center;

    >.title {
      font-size: 22px;
      color: $main-blue;
      font-weight: bold;
    }

    >.buttons {
      display: flex;
      flex: 0 0 auto;
      flex-direction: row;

      >.status {
        margin: auto 10px;
      }

      >.status-success {
        color: $green;
      }

      >.status-error {
        color: $dark-red;
      }

      >.create-button {
        border: 1px solid $main-blue;
        color: $main-blue;
        background: $white;
        border-radius: 5px;
        font-size: 18px;
        cursor: pointer;
        outline: none;
        padding: 5px;

        &:disabled {
          color: $grey;
          border-color: $grey;
          pointer-events: none;
        }
      }
    }
  }

  >.parts {
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    flex-wrap: wrap;
    overflow-y: auto;
    height: calc(100% - #{$header-height});

    >.part-wrapper {
      display: flex;
      flex: 0 0 auto;
      flex-direction: column;
      padding: 5px;

      width: 25%;

      @media (max-width: 1300px) {
        & {
          width: 33%;
        }
      }

      @media (max-width: 1100px) {
        & {
          width: 50%;
        }
      }

      @media (max-width: 800px) {
        & {
          width: 100%;
        }
      }

      >div {
        flex: 0 0 auto;
        margin-bottom: 10px;
        width: 100%;

        >input {
          width: 100%;
          max-width: 100%;
        }
      }

      >.title {
        display: flex;
        flex: 0 0 auto;
        flex-direction: row;
        justify-content: space-between;
        border-bottom: 1px solid $main-blue;
        padding-bottom: 2px;

        >.header {
          color: $main-blue;
          font-size: 18px;
        }

        >.clear-button {
          background: $main-blue;
          color: $white;
          font-size: 16px;
          padding: 5px;
          border: none;
          border-radius: 5px;
          cursor: pointer;
          outline: none;
        }
      }
    }
  }
}
