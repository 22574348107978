@import "../../../../../../../../../../../../../../style-variables-colors.scss";


.c-PatientListItemDetails {
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;

  >.data-row {
    display: flex;
    flex-direction: row;
    flex: 0 0 auto;

    >.label {
      font-weight: 600;
      margin-right: 4px;
    }
  }

  >.detail {
    color: $text-light-grey;
  }
}
