@import '../../../../../../../../style-variables-colors.scss';


.c-LabelsView {
  display: flex;
  flex: 1 1 0px;
  overflow: hidden;

  .drugs-list {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 12px 12px;
    flex: 0 0 300px;

    .formulary-entry {
      padding: 4px 12px;
      border: solid 1px #CCCCCC;
      cursor: pointer;
    }
  }

  .label-construction-area {
    display: flex;
    flex-direction: column;
    padding: 24px;
    flex: 1 1 auto;
    overflow: hidden;

    .print-area {
      display: flex;
      flex-direction: row;
      gap: 12px;

      .preview-area {
        flex: 0 0 auto;

        .error-message {
          padding: 40px;
          font-size: 20px;
          font-weight: 600;
          color: #CF6059;
        }
      }

      .printer-area {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .print-controls {
  
        }
      }
    }

    .input-container {
      display: flex;
      flex-direction: column;
      flex: 1 1 0px;

      label {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 4px;
      }
    }

    .keycard-ignoring-input {
      border: solid 1px #CCCCCC;
      border-radius: 5px;
      padding: 8px 12px;
      font-size: 16px;
      color: #333333;
      font-weight: 600;
    }

    .inputs-area {
      display: flex;
      flex-wrap: wrap;
      gap: 12px;
      margin-top: 24px;
      margin-bottom: 24px;
    }

    .warning-labels {
      display: flex;
      flex-direction: column;
      gap: 12px;

      .warning-label {
        padding: 8px 16px;
        box-shadow: -4px 0px 3px 0px rgba(0, 0, 0, 0.1);
        cursor: pointer;

        &.selected {
          box-shadow: -4px 0px 3px 0px rgba(107, 161, 203, 0.6);
          color: #6BA1CB;
        }
      }
      
    }
  }
}
