@import '../../../../../../../../../../../../../../style-variables-colors.scss';

.c-KioskListItemTemperature {
  display: flex;
  flex-direction: row;

  >.temperature-image {
    height: 25px;
    width: 25px;
  }
}

.c-KioskListItemTemperature-red {
  color: $dark-red;
}
