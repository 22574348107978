@import "../../../../../../../../../../style-variables-colors.scss";

.c-PrescriptionFillsManage {
  .c-TableHeader {
    >.right-area {
      >.c-DatePeriodPicker {
        margin-right: 50px;
      }
    }
  }

  .content-row {
    display: flex;

    .content-cell {
      display: flex;
      flex-direction: column;

      .internal-label {
        font-weight: 600;
      }

      .actions {
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        button {
          background-color: white;
          border: solid 2px #ccc;
          border-radius: 5px;
          padding: 4px 12px;
          font-size: 14px;
          cursor: pointer;
          width: min-content;
          margin-bottom: 15px;

          &:last-of-type {
            margin-bottom: 0;
          }
        }
        .edit {

        }

        >.receipt {
          background-color: $main-blue;
          color: $white;
        }

        >.cancel {
          background-color: $dark-red;
          color: $white;
        }
      }
    }
  }

  .red { 
    color: $dark-red;
  }
  
  .green {
    color: $green;
  }
}
