@import '../../../../../style-variables-colors.scss';

.c-DispenseAttemptRejectionReasonModal {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  font-size: 20px;

  >.rejection-options {
    display: flex;
    flex: 1 1 auto;
    justify-content: space-between;
    padding: 20px 0;

    >input[checkbox] {

    }
  }

  >.reason-select {
    padding-bottom: 10px;

    >.Select-control {
      cursor: pointer;
    }
  }

  >.text-input {
    font-size: 20px;
    height: 80%;
    resize: none;
    outline: none;
    border: 1px solid $light-grey;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    padding: 10px;
    margin-bottom: 20px;
  }
}
