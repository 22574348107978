@import "../../../../../../../../../../../../../style-variables-colors.scss";


.c-PendingVideoConferenceNotification {
  display: flex;
  flex: 0 0 auto;
  width: 100%;

  >.actions {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: 0 1 auto;

    >.icon {
      height: 34px;
      width: 34px;
      padding: 5px;
      cursor: pointer;
      border: 2px solid $lighter-grey;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    >.green-border {
      border-color: $green;
    }

    >.red-border {
      border-color: $dark-red;
    }
  }
}
