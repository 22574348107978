@import "../../../../../../../../../../../style-variables-colors.scss";


.c-DispenseAttemptsNotifications {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: 100%;

  >.no-data-caption {
    display: flex;
    flex: 1 1 auto;
    justify-content: center;
    align-items: center;
    color: $darker-grey;
    font-size: 20px;
  }
}
