@import "../../../../../../../../../../../../style-variables-colors.scss";

.export-simplified-inventory-btn {
  font-size: 16px;
  width: 220px;
  height: 50px;
  color: $white;
  background-color: $main-blue;
  outline: none;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}
