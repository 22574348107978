@import '../../../../../../../style-variables-colors.scss';


.c-webCamPreview {
  max-width: 70%;
  border: 3px solid $main-blue;
  border-radius: 8px;
  margin-bottom: 10px;
  overflow: hidden;
  width: 400px;
  height: 400px;
  background: $light-grey;

  >.video {
    width: 100%;
    height: 100%;
  }
}
