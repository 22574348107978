$navy: #375362;
$dark-blue: #1A272E;
$gold: #FDBF21;
$charcoal: #0A1612;
$light-grey: #E6E7E8;
$transparent-grey: #f8f8f9;
$darker-grey: #989EA1;
$blur-white: rgba(235, 235, 235, 0.61);
$off-white: #F8F9F9;
$white: #FFFFFF;
$light-blue: #1183C0;
$pale-blue: #b4def9;

$turquoise: #13C7AF;
$pink-red: #E83152;
$dark-red: #CF6059;
$allergies-color: #df968f;
$green: #07A567;
$light-green: #3ab96f;
$lighter-green: #44c745;
$border-grey: #E2E7E9;

$text-primary: #19272E;
$text-light-grey: #9298A0;
$text-mid-grey: #71777A;

$border-light:  #EBEBEB;

$main-blue: #59A3CF;
$grey: #808080;
$red: #ff0000;
$black: #000000;
$dark-grey: #404040;
$spec-white: #F2F6F8;
$input-shadow-from: rgba(0, 0, 0, 0.24);
$input-shadow-to: rgba(0, 0, 0, 0.1);
$lighter-blue: #cce3ff;
$lighter-grey: #c0c0c0;
$grey-kiosk-border: #D8D8D8;
