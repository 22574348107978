@import "../../../../../../../style-variables-colors.scss";

$header-height: 51px;

.c-HeaderView {
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
  align-items: center;
  height: $header-height;
  background-color: #F9FAFB;
  padding: 0 24px;
  border-bottom: solid 1px #E2E7E9;
  justify-content: space-between;

  .left-section {
    padding: 14px 0;

    .medifriendrx-logo {
      font-size: 19px;
      line-height: 24px;
      color: #59A3CF;
      font-weight: 400;

      span {
        font-weight: 600;
      }
    }
  }

  >.right-section {
    display: flex;
    flex-direction: row;
    height: 100%;

    >.enter-away-mode-btn {
      align-self: center;
      font-size: 16px;
      width: 155px;
      height: 35px;
      color: $white;
      background-color: $dark-red;
      outline: none;
      border: none;
      border-radius: 3px;
      cursor: pointer;
    }

    >.user-select {
      align-self: center;
      padding-left: 20px;
      z-index: 1100;
      outline: none;

      .user-select-option {
        padding: 10px;
        border-bottom: 1px solid $light-grey;
        cursor: pointer;

        &:hover {
          background-color: rgba(38, 132, 255, 0.08);
        }

        &:last-of-type {
          border-bottom: none;
        }

        >.label {
          font-size: 16px;
          margin-bottom: 2px;
        }

        >.email {
          color: $darker-grey;
          font-size: 12px;
        }
      }
    }

    >.call-history, >.active-call-history {
      display: flex;
      align-content: center;
      justify-content: center;
      width: max-content;
      padding: 15px;
      background: $white;
      border-right: 1px solid $light-grey;
      border-left: 1px solid $light-grey;

      >img {
        width: 20px;
        height: 20px;
      }
    }

    >.active-call-history {
      background: $main-blue;
    }

    >.user-image {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 25px;
      cursor: pointer;

      >img {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background: $darker-grey;
      }
    }
  }
}
