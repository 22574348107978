@import "../../../../../../../../../../../../style-variables-colors.scss";


.technician {
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;

  >.technician-location {
    color: $text-light-grey;
  }
}
