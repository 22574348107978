@import '../../../../../../../../../../../../style-variables-colors.scss';


.c-AutocompleteCaptionValueInput {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: 100%;
  width: 100%;
  margin-right: 50px;

  >.autocomplete-caption-value-input-container {
    position: relative;

    >.input-container {
      display: flex;
      flex: 0 1 auto;
      align-items: flex-start;
      justify-content: flex-start;

      >.icon {
        width: 20px;
        height: 20px;
        margin-top: 5px;
      }
    }

    >.suggestions {
      position: absolute;
      background: $white;
      width: 100%;
      border: 1px solid $light-grey;
      border-top: none;
      -webkit-border-radius: 3px 3px 5px 5px;
      -moz-border-radius: 3px 3px 5px 5px;
      border-radius: 3px 3px 5px 5px;
      z-index: 1000;
  
      display: flex;
      flex-direction: column;
      max-height: 200px;
      overflow-y: auto;
  
      >.suggestion {
        font-size: 18px;
        padding: 5px 5px 8px;
        border-bottom: 1px solid $light-grey;
        margin-top: 5px;
  
        &:last-of-type {
          padding-bottom: 0;
          border-bottom: none;
        }
  
        &:hover {
          cursor: pointer;
          background: $light-grey;
        }
      }
    }
  }

}
