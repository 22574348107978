@import "../../../style-variables-colors.scss";

.c-CaptionValueInput {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  width: 100%;
  margin-right: 10px;

  >.input, >.input-required {
    height: 30px;
    max-width: 120px;
    padding: 1px 5px;
    font-size: 14px;
    border: 2px solid $light-grey;
    border-radius: 5px;
    outline: none;
  }

  >.input-required {
    border-color: $dark-red;
  }

  >.max-width {
    max-width: 100%;
  }

  >.validation-error {
    color: $dark-red;
  }

  >.caption {
    text-transform: uppercase;
    color: $grey;
  }

  &:last-child {
    margin-right: 0;
  }
}
